import React, { useState, useEffect } from "react";
import "./scroll-to-top-icon.css"; // Import the styles for your scroll button
import { IoIosArrowUp } from "react-icons/io";

const ScrollToTopIcon = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show or hide the button based on the scroll position
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      // Show button after 300px scroll
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };

  // Attach the scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility); // Cleanup listener
    };
  }, []);

  return (
    <div className="scroll-to-top">
      {isVisible && (
        <button onClick={scrollToTop} className="scroll-to-top-btn">
          <IoIosArrowUp />
          TOP
        </button>
      )}
    </div>
  );
};

export default ScrollToTopIcon;
