import { useState, useEffect } from "react";
import { BiUpload } from "react-icons/bi";

import logo from "../../../assets/icons/logo2.png";
import "./create-novel.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import NovelModal from "./createNovelModal";
import { useCreateNovel } from "../../../redux/actions/bookActions";
import { useGetGenres } from "../../../redux/actions/genresActions";
import { useGetTags } from "../../../redux/actions/tagsActions";
import toastManager from "../../../components/toast/ToasterManager";
import { MdCloudUpload, MdDelete } from "react-icons/md";
import { AiFillFileImage } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";

function CreateNovel() {
  const navigate = useNavigate();
  const genres = useGetGenres();
  const tags = useGetTags();
  const createNovel = useCreateNovel();
  const [allGenres, setAllGenres] = useState([]);
  const [tagsArray, setTagsArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState("");

  const [formData, setFormData] = useState({
    image: null,
    bookTitle: "",
    language: "",
    leadingGender: "",
    genres: [],
    description: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleImageChange = (e) => {
  //   setFormData({ ...formData, image: e.target.files[0] });
  // };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedChips, setSelectedChips] = useState([]);
  const [chipsData, setChipsData] = useState([]);

  // Handle change for genres (multiple select)
  const handleGenreChange = (e) => {
    const selectedGenre = e.target.value;

    // Check if the genre is already selected
    if (formData.genres.includes(selectedGenre)) {
      // If the genre is already selected, remove it
      setFormData({
        ...formData,
        genres: formData.genres.filter((genre) => genre !== selectedGenre),
      });
    } else {
      // If the genre is not selected, add it to the list
      setFormData({
        ...formData,
        genres: [...formData.genres, selectedGenre],
      });
    }
  };

  const handleChipSelect = (chip) => {
    setSelectedChips((prev) =>
      prev.includes(chip)
        ? prev.filter((_chip) => _chip.id !== chip.id)
        : [...prev, chip]
    );
  };

  const handleConfirm = () => {
    setIsModalOpen(false);
  };

  const handleTags = async () => {
    try {
      setLoading(true);
      const response = await tags();

      if (response.payload && Array.isArray(response.payload.results)) {
        const data = response.payload.results;
        setTagsArray(data);

        // Step 1: Group by category
        const groupedByCategory = data.reduce((acc, item) => {
          if (!acc[item.category]) {
            acc[item.category] = [];
          }
          acc[item.category].push(item);
          return acc;
        }, {});

        // Step 2: Map to desired structure
        const transformedData = Object.keys(groupedByCategory).map(
          (categoryId) => ({
            title: `Category ${categoryId}`, // Customize title as needed
            chips: groupedByCategory[categoryId].map((item) => ({
              id: item.id,
              label: item.name,
              selected: selectedChips.includes(item.id),
            })),
          })
        );

        setChipsData(transformedData);
      } else {
        console.log(response.message || "Failed to fetch shelf");
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchGenres = async () => {
    try {
      setLoading(true);
      const response = await genres();

      if (response.payload && Array.isArray(response.payload.results)) {
        setAllGenres(response.payload.results);
      } else {
        console.log(response.message || "Failed to fetch genres");
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleTags();
    fetchGenres();
  }, []);

  const handleCreate = async (e) => {
    e.preventDefault();

    if (formData.description?.length < 150) {
      toastManager.addToast({
        message: "Synopsis should be at least 150 characters long",
        type: "error",
      });
      return;
    }
    const selectedTags = selectedChips.map((item) => item.label);

    try {
      setLoading(true);
      const response = await createNovel({
        title: formData.bookTitle,
        language: formData.language,
        leading_gender: formData.leadingGender,
        description: formData.description,
        genres: formData.genres,
        tags: selectedTags,
      });

      if (response.payload || response.payload.status === "success") {
        setFormData({
          image: null,
          bookTitle: "",
          language: "",
          leadingGender: "",
          genres: [],
          description: "",
        });
        setFileName(null);
        setImage(null);
        setSelectedChips([]);
        toastManager.addToast({
          message: "Novel crated succesfully",
          type: "success",
        });
        navigate(`/book-chapters/${response.payload.id}`);
        return;
      } else {
        toastManager.addToast({
          message: "Novel creation failed",
          type: "error",
        });
      }
    } catch (error) {
      toastManager.addToast({
        message: error.response?.message || "Novel creation failed",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="create__novel">
      <h1>Novel Information</h1>
      <form onSubmit={handleCreate}>
        <div className="create__book__upload">
          <div>
            <label for="bookTitle">Book Cover </label>

            <div
              className="image__upload"
              onClick={() => document.querySelector(".input__field").click()}
            >
              <input
                name="image"
                type="file"
                accept="image/*"
                className="input__field"
                hidden
                onChange={({ target: { files } }) => {
                  files[0] && setFileName(files[0].name);
                  if (files) {
                    const reader = new FileReader();
                    reader.readAsDataURL(files[0]);
                    reader.onloadend = () => {
                      setImage(reader.result);
                    };
                    setFormData({ ...formData, image: files[0] });
                  }
                }}
              />

              {formData.image ? (
                <img
                  src={image}
                  width={75}
                  height={75}
                  alt={fileName}
                  className="input__preview"
                />
              ) : (
                <MdCloudUpload color="#669933" size={50} />
              )}
            </div>
            <div className="image__upload__label">
              <AiFillFileImage color="#1475" />
              <span>
                {fileName}
                <MdDelete
                  onClick={() => {
                    setFileName("No file selected");
                    setImage(null);
                    setFormData({ ...formData, image: null });
                  }}
                />
              </span>
            </div>
            <input
              type="file"
              id="bookCover"
              name="bookCover"
              accept=".jpg,.png"
            />
          </div>
        </div>
        <span className="create__novel__form__input__block entire--span">
          <label for="bookTitle">Book Title</label>
          <input
            name="bookTitle"
            value={formData.bookTitle}
            type="text"
            id="bookTitle"
            required
            onChange={handleChange}
          />
        </span>
        <span className="create__novel__form__input__block">
          <label for="language">Language</label>
          <select
            required
            name="language"
            id="language"
            value={formData.language}
            onChange={handleChange}
            className="modal__apply__input"
          >
            <option value={null}>--</option>
            <option value="en">English</option>
          </select>
        </span>
        <span className="create__novel__form__input__block">
          <label for="leadingGender">Leading Gender</label>
          <select
            required
            name="leadingGender"
            id="leadingGender"
            value={formData.leadingGender}
            onChange={handleChange}
            className="modal__apply__input"
          >
            <option value={null}>--</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
          </select>
        </span>{" "}
        <span className="create__novel__form__input__block">
          <label for="genre">Genre</label>
          <select
            required
            name="genres"
            id="genres"
            value={formData.genres}
            onChange={handleGenreChange}
            className="modal__apply__input"
          >
            <option value={null}>--</option>
            {allGenres.map((genre, id) => (
              <option key={id} value={genre.name}>
                {genre.name}
              </option>
            ))}
          </select>
        </span>
        {/* Display selected genres */}
        <div style={{ marginBottom: "20px" }}>
          {formData.genres.length > 0 ? (
            <ul
              style={{
                listStyleType: "none",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                gap: "20px",
              }}
            >
              {formData.genres.map((genre, index) => (
                <li
                  key={index}
                  style={{
                    padding: "10px 15px",
                    borderRadius: "30px",
                    backgroundColor: "#66993322",
                    color: "#669933",
                    fontSize: "12px",
                  }}
                >
                  {genre}
                </li>
              ))}
            </ul>
          ) : null}
        </div>
        <span className="create__novel__form__input__block">
          <label for="tags">Tags</label>

          <button
            type="button"
            className="novel-modal-select-button"
            onClick={() => setIsModalOpen(true)}
          >
            {selectedChips.length === 0
              ? "Select Chips"
              : selectedChips.map((chip) => {
                  return (
                    <div key={chip.id} className="chip selected">
                      {chip.label}
                    </div>
                  );
                })}
          </button>
          <NovelModal
            sections={chipsData}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSelectChip={handleChipSelect}
            selectedChips={selectedChips}
            onConfirm={handleConfirm}
          />
        </span>
        <span className="create__novel__form__input__block ">
          <label for="description">Synopsis</label>
          <textarea
            required
            name="description"
            id="description"
            value={formData.description}
            onChange={handleChange}
            className="create__book__form__input__block__textarea"
          ></textarea>
        </span>
        <button disabled={loading} type="submit">
          {loading ? <ClipLoader color="#fff" size={20} /> : "Create"}
        </button>
      </form>
    </div>
  );
}

export default CreateNovel;
