import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "./Account.css";
import Header from "../../../components/layout/header/Header";

const NAV_ITEMS = [
  { id: 1, label: "Dashboard", path: "/account-dashboard" },
  { id: 2, label: "Stories", path: "/account-stories" },
  // { id: 3, label: "Income", path: "/account-income" },
  { id: 4, label: "Transaction History", path: "/account-transaction-history" },
  { id: 5, label: "Inbox", path: "/account-inbox" },
  { id: 6, label: "Setting", path: "/account-profile" },
];

function Account() {
  const navigate = useNavigate();
  const [activeId, setActiveId] = useState(1);

  const handleNavigation = (id, path) => {
    setActiveId(id);
    navigate(path);
  };

  return (
    <div className="account__main">
      <Header />
      <section className="account">
        <div className="account__navbar">
          <ul className="account__navbar__ul">
            {NAV_ITEMS.map(({ id, label, path }) => (
              <li
                key={id}
                className={`account__navbar__ul__li${
                  activeId === id ? " activeId" : ""
                }`}
                onClick={() => handleNavigation(id, path)}
              >
                {label}
              </li>
            ))}
          </ul>
        </div>
        <div className="account__outlet">
          <Outlet />
        </div>
      </section>
    </div>
  );
}

export default Account;
