import "./Ranking.css";
import Footer from "../../../components/layout/footer/Footer";
import Header from "../../../components/layout/header/Header";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRanking } from "../../../redux/actions/bookActions";
import Loading from "../../../components/splash/loading/Loading";
import NoResult from "../../../components/splash/no-result/NoResult";
import truncateDescription from "../../../utils/truncate";
import defaultBookCover from "../../../assets/images/defaultBookCover.png";
import { FaEye } from "react-icons/fa";

function Ranking() {
  const [selectedNav, setSelectedNav] = useState("");
  const [filteredRankings, setFilteredRankings] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const ranking = useRanking();

  const handleRanking = async (data) => {
    let _data = data;

    if (data === "") {
      _data = "";
    }

    try {
      setSelectedNav(data);
      setLoading(true);
      const response = await ranking(_data);

      if (response.payload && Array.isArray(response.payload.results)) {
        setFilteredRankings(response.payload.results);
      } else {
        console.log(response.message || "Unexpected response structure");
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleRanking(selectedNav);
  }, [selectedNav]);

  return (
    <div className="ranking">
      <div className="ranking__section">
        <Header />
        <section className="ranking__section__one">
          <div className="ranking__section__one__nav">
            <h1>Ranking</h1>
            <div className="genre__section__one__nav__options__wrap">
              <div className="genre__section__one__nav__options">
                <h3
                  onClick={() => handleRanking("")}
                  className={`genre__section__one__nav__options__h3 ${
                    selectedNav === "" ? "active" : ""
                  }`}
                >
                  All
                </h3>
                <h3
                  onClick={() => handleRanking("power")}
                  className={`genre__section__one__nav__options__h3 ${
                    selectedNav === "power" ? "active" : ""
                  }`}
                >
                  Power
                </h3>
                <h3
                  onClick={() => handleRanking("trending")}
                  className={`genre__section__one__nav__options__h3 ${
                    selectedNav === "trending" ? "active" : ""
                  }`}
                >
                  Trending
                </h3>
                <h3
                  onClick={() => handleRanking("popular")}
                  className={`genre__section__one__nav__options__h3 ${
                    selectedNav === "popular" ? "active" : ""
                  }`}
                >
                  Popular
                </h3>
                <h3
                  onClick={() => handleRanking("active")}
                  className={`genre__section__one__nav__options__h3 ${
                    selectedNav === "active" ? "active" : ""
                  }`}
                >
                  Active
                </h3>
              </div>
            </div>
          </div>
          <div className="ranking__section__one__body">
            {loading ? (
              <Loading />
            ) : filteredRankings.length === 0 ? (
              <NoResult />
            ) : (
              <div className="ranking__section__one__body__display">
                {filteredRankings.map((item, i) => (
                  <div
                    key={i}
                    onClick={() => navigate(`/book/${item.id}`)}
                    className="genre__section__one__body__display__block"
                  >
                    <img
                      alt=""
                      src={
                        item?.cover_image ? item.cover_image : defaultBookCover
                      }
                    />
                    <div className="genre__section__one__display__block__about">
                      <h1>{item.title && item.title}</h1>
                      <span>
                        <h1>{item.author && item.author.name}</h1>{" "}
                        <h2>
                          <FaEye />
                          {item.num_views && item.num_views}
                        </h2>
                      </span>
                      <p>
                        {item.description &&
                          truncateDescription(item.description, 90)}
                      </p>
                      <article>
                        {item.tags.map((item, i) => {
                          return <h1 key={i}>{item}</h1>;
                        })}
                      </article>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Ranking;
