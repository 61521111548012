import { useEffect, useState } from "react";
import Footer from "../../../components/layout/footer/Footer";
import Header from "../../../components/layout/header/Header";
import "./book-chapters.css";
import TextEditor from "../../../components/ui/text-editor/TextEditor";
import {
  useCreateNovelChapter,
  useGetChapterDraft,
  useGetMyNovelChapters,
  usePatchChapterDraft,
  useRequestPublish,
} from "../../../redux/actions/bookActions";
import { useParams } from "react-router-dom";
import toastManager from "../../../components/toast/ToasterManager";
import NoResult from "../../../components/splash/no-result/NoResult";
import Loading from "../../../components/splash/loading/Loading";
import TextEditorEdit from "../../../components/ui/text-editor-edit/TextEditorEdit";
import Modal from "../../../components/modal/Modal";
import truncateDescription from "../../../utils/truncate";

function BookChapters() {
  const createNovelChapter = useCreateNovelChapter();
  const getChapterDraft = useGetChapterDraft();
  const getMyNovelChapters = useGetMyNovelChapters();
  const patchChapterDraft = usePatchChapterDraft();
  const requestPublish = useRequestPublish();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [loadingChapters, setLoadingChapters] = useState(false);
  const [novelChapters, setNovelChapters] = useState(null);
  const [chapterDraft, setChapterDraft] = useState([]);
  const [title, setTitle] = useState("");
  const [contentWrite, setContentWrite] = useState("");
  const [contentEdit, setContentEdit] = useState("");
  const [chapterId, setChapterId] = useState(null);
  const [write, setWrite] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isChapterOpen, setIsChapterOpen] = useState(false);
  const [newChapterCreatedAlready, setNewChapterCreatedAlready] =
    useState(false);
  const [isOpen, setIsOpen] = useState({
    publishEdit: false,
    publishWrite: false,
  });
  const closeModal = () => {
    setIsOpen({
      publishEdit: false,
      publishWrite: false,
    });
  };
  const handleModalClick = (option) => {
    if (option === "publishEdit") {
      setIsOpen((prev) => ({ ...prev, publishEdit: true }));
    } else if (option === "publishWrite") {
      setIsOpen((prev) => ({ ...prev, publishWrite: true }));
    } else {
      closeModal();
    }
  };

  const onPublishEditClick = () => {
    handleEditDraft();
    handleModalClick("publishEdit");
  };

  const onPublishWriteClick = () => {
    handleSaveAsDraft();
    handleModalClick("publishWrite");
  };

  const handleGetNovelChapters = async () => {
    try {
      setLoadingChapters(true);
      const response = await getMyNovelChapters(id);

      if (response.payload) {
        setNovelChapters(response.payload.results);
      } else {
        console.log(response.message || "Failed to fetch chapters");
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoadingChapters(false);
    }
  };

  const handleEditChapter = (chapId) => {
    setEdit(true);
    handleGetChapterDraft(chapId);
  };

  const handleGetChapterDraft = async (chapId) => {
    const payload = {
      chapterId: chapId,
      novelId: id,
    };
    try {
      setLoading(true);
      const response = await getChapterDraft(payload);

      if (response.payload) {
        setChapterDraft(response.payload);
      } else {
        console.log(response.message || "Failed to fetch draft");
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveAsDraft = async () => {
    if (title.length == 0) {
      toastManager.addToast({
        message: "Title cannot be empty",
        type: "warning",
      });
      return;
    }
    if (contentWrite.length == 0) {
      toastManager.addToast({
        message: "Content cannot be empty",
        type: "warning",
      });
      return;
    }
    try {
      setLoadingDraft(true);

      console.log("current chapters", novelChapters);

      // Check if any chapter in the array has the given chapterId
      const chapterExists = novelChapters.some(
        (chapter) => chapter.chapter == chapterId
      );

      let createNovelResponse;

      if (!chapterExists) {
        createNovelResponse = await createNovelChapter({
          novelId: id,
          title: title,
        });
        setChapterId(parseFloat(createNovelResponse.payload.chapter));
      }

      const payload = {
        chapterId: chapterId
          ? chapterId
          : parseFloat(createNovelResponse.payload.chapter),
        novelId: id,
        title: title,
        content: contentWrite,
      };

      const response = await patchChapterDraft(payload);

      if (
        response.payload &&
        response.payload.message === "Updated successfully"
      ) {
        toastManager.addToast({
          message: "Draft saved succesfully",
          type: "success",
        });
      } else {
        toastManager.addToast({
          message: "Failed to saved draft",
          type: "error",
        });
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      handleGetNovelChapters();
      setLoadingDraft(false);
    }
  };

  const handleEditDraft = async () => {
    if (title.length == 0 && !chapterDraft.title) {
      toastManager.addToast({
        message: "Title cannot be empty",
        type: "warning",
      });
      return;
    }
    if (contentEdit.length == 0) {
      toastManager.addToast({
        message: "Content cannot be empty",
        type: "warning",
      });
      return;
    }
    try {
      setLoadingDraft(true);

      const payload = {
        chapterId: chapterId,
        novelId: id,
        title: chapterDraft.title,
        content: contentEdit,
      };

      const response = await patchChapterDraft(payload);

      if (
        response.payload &&
        response.payload.message === "Updated successfully"
      ) {
        toastManager.addToast({
          message: "Draft edited succesfully",
          type: "success",
        });
      } else {
        toastManager.addToast({
          message: "Failed to edit draft",
          type: "error",
        });
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      handleGetNovelChapters();
      setLoadingDraft(false);
    }
  };

  const handlePublishWrite = async () => {
    try {
      if (title.length == 0) {
        toastManager.addToast({
          message: "Title cannot be empty",
          type: "warning",
        });
        return;
      }
      if (contentWrite.length == 0) {
        toastManager.addToast({
          message: "Content cannot be empty",
          type: "warning",
        });
        return;
      }

      const payload = {
        chapterId: chapterId,
        novelId: id,
        title: title,
        content: contentWrite,
      };

      const response = await requestPublish(payload);

      if (response.payload && response.meta.requestStatus == "fulfilled") {
        toastManager.addToast({
          message: "Request for publish successful",
          type: "success",
        });
      } else {
        toastManager.addToast({
          message: "Failed to request for publish",
          type: "error",
        });
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoadingPublish(false);
      handleGetChapterDraft(chapterId);
      setWrite(false);
      closeModal();
    }
  };

  const handlePublishEdit = async () => {
    try {
      setLoadingPublish(true);

      const payload = {
        chapterId: chapterId,
        novelId: id,
        title: title,
        content: contentWrite,
      };

      const response = await requestPublish(payload);

      if (response.payload && response.meta.requestStatus == "fulfilled") {
        toastManager.addToast({
          message: "Request for publish successful",
          type: "success",
        });
      } else {
        toastManager.addToast({
          message: "Failed to request for publish",
          type: "error",
        });
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoadingPublish(false);
      handleGetChapterDraft(chapterId);
      setEdit(false);
      closeModal();
    }
  };

  const handleNewChapter = () => {
    setWrite(true);
  };

  const handleCloseEditor = () => {
    handleGetChapterDraft(chapterId);
    setChapterId(null);
    setTitle("");
    setContentWrite("");
    setWrite(false);
    setEdit(false);
  };

  const handleOnClickChapter = (chapId) => {
    handleGetChapterDraft(chapId);
    setIsChapterOpen(true);
  };

  useEffect(() => {
    handleGetNovelChapters(id);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [edit, write, isChapterOpen]);

  return (
    <div className="book__chapters__bg">
      <div className="book__chapters">
        <Header />
        <section className="book__chapters__section__one">
          <section className="book__chapters__nav">
            {write || edit ? (
              <button
                onClick={handleCloseEditor}
                style={{ backgroundColor: "#12121222", color: "#121212" }}
              >
                Close Editor
              </button>
            ) : (
              <button onClick={handleNewChapter}>New Chapter</button>
            )}

            {novelChapters?.length > 0 && !write && !edit && (
              <div className="book__chapters__nav__chapters__wrap">
                {novelChapters.map((chapter, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setChapterId(parseFloat(chapter.chapter));
                      handleOnClickChapter(parseFloat(chapter.chapter));
                    }}
                    className={`book__chapters__nav__chapters ${
                      chapterId == parseFloat(chapter.chapter) ? " active" : ""
                    }`}
                  >
                    <h3>
                      {`Chapter ${parseFloat(
                        chapter.chapter
                      )}: ${truncateDescription(chapter.title, 20)}` ||
                        "Untitled Chapter"}
                    </h3>
                    <p>
                      {chapter.published
                        ? `${chapter.wordCount} Words | ${chapter.date} | ${chapter.time}`
                        : "Unpublished"}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </section>
          <section className="book__chapters__body">
            <div className="book__chapters__body__content">
              {loadingChapters && !write && !edit && <Loading />}
              {novelChapters?.length === 0 && !write ? (
                <NoResult
                  header="No chapters created"
                  content="Create new chapter to get started"
                />
              ) : null}

              {/* TEXT EDITOR FOR NEW CHAPTER */}
              {write ? (
                <>
                  <div className="book__chapters__body__header">
                    <input
                      type="text"
                      placeholder="Enter chapter title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      className="book__chapters__body__header__chaptertitle"
                      style={{ width: "100%" }}
                    />
                    <h3></h3>
                  </div>
                  <TextEditor
                    loadingDraft={loadingDraft}
                    loadingPublish={loadingPublish}
                    setContent={setContentWrite}
                    saveAsDraft={handleSaveAsDraft}
                    requestPublish={onPublishWriteClick}
                    content={contentWrite}
                  />
                </>
              ) : null}

              {/* TEXT EDITOR FOR EDIT */}
              {edit ? (
                <>
                  <div className="book__chapters__body__header">
                    <input
                      type="text"
                      placeholder="Chapter Title"
                      value={chapterDraft.title}
                      onChange={(e) =>
                        setChapterDraft((prevState) => ({
                          ...prevState,
                          title: e.target.value,
                        }))
                      }
                      className="book__chapters__body__header__chaptertitle"
                    />
                    <h3></h3>
                  </div>
                  <TextEditorEdit
                    loadingDraft={loadingDraft}
                    loadingPublish={loadingPublish}
                    setContent={setContentEdit}
                    saveAsDraft={handleEditDraft}
                    requestPublish={onPublishEditClick}
                    content={chapterDraft.draft}
                  />
                </>
              ) : null}

              {isChapterOpen && !write && !edit && (
                <div className="">
                  {loading ? (
                    <Loading />
                  ) : (
                    <div className="">
                      <h1 className="book__chapters__view__title">
                        {`CHAPTER ${parseFloat(
                          chapterDraft.chapter && chapterDraft.chapter
                        )}: ${chapterDraft.title && chapterDraft.title}` ||
                          "Untitled Chapter"}
                      </h1>
                      <span
                        className="bp__section__two__info"
                        style={{
                          textAlign: "center",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          gap: "10px",
                          fontWeight: "600",
                          color: "#777",
                        }}
                      >
                        {/* <p>Word Count: 917</p> */}
                        <p>{`State: ${
                          chapterDraft.draft_state && chapterDraft.draft_state
                        }`}</p>
                      </span>
                      <span
                        style={{
                          textAlign: "center",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        {chapterDraft.draft_state != "awaiting approval" && (
                          <button
                            onClick={() =>
                              handleEditChapter(
                                parseFloat(chapterDraft.chapter)
                              )
                            }
                            style={{
                              background: "#12121222",
                              color: "#121212",
                            }}
                          >
                            EDIT DRAFT
                          </button>
                        )}
                        <button
                          onClick={() => setIsChapterOpen(false)}
                          style={{
                            // background: "#66993322",
                            // color: "#669933",
                            backgroundColor: "#e74c3c22",
                            color: "#e74c3c",
                          }}
                        >
                          CLOSE CHAPTER
                        </button>
                      </span>
                      <p
                        className="bp__section__two__text"
                        dangerouslySetInnerHTML={{ __html: chapterDraft.draft }}
                      ></p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </section>
        </section>
      </div>
      <Footer />

      {/* CONFIRM PUBLICATION REQUEST MODAL */}
      <Modal isOpen={isOpen.publishEdit} onClose={closeModal}>
        <div className="admin__modal">
          <h1>Confirm request for publish</h1>
          <p>
            You will not be able to edit your draft afterwards pending approval
          </p>
          <button onClick={handlePublishEdit}>Request Publish</button>
        </div>
      </Modal>

      <Modal isOpen={isOpen.publishWrite} onClose={closeModal}>
        <div className="admin__modal">
          <h1>Confirm request for publish</h1>
          <p>
            You will not be able to edit your draft afterwards pending approval
          </p>
          <button onClick={handlePublishWrite}>Request Publish</button>
        </div>
      </Modal>
    </div>
  );
}

export default BookChapters;
