import "./Account.income.css";

function AccountIncome() {
  return (
    <div>
      <section className="ac__income__section__one">
        <p>Income</p>
        <p>Invoice:</p>
      </section>
    </div>
  );
}

export default AccountIncome;
