import Footer from "../../../components/layout/supplementary/footer/Footer";
import Header from "../../../components/layout/supplementary/header/Header";
import "./privacy.css";

function Privacy() {
  return (
    <>
      <Header />
      <div className="privacy">
        <h1>Privacy policy</h1>
        <h3>Introduction</h3>
        <p>
          Magna do ut elit mollit aliquip id laborum in minim reprehenderit
          ipsum. Duis commodo tempor et duis enim aute et nostrud minim est.
          Anim magna elit velit elit minim est occaecat magna ea quis duis irure
          veniam. Nisi sunt et dolor incididunt fugiat incididunt laboris
          aliquip laborum sunt ullamco dolor aliqua. Id commodo sit sint
          pariatur magna incididunt consectetur labore irure eu ullamco labore.
        </p>

        <h3>Terms of service</h3>
        <p>
          Labore non voluptate consequat aliquip aute nulla Lorem laborum
          commodo cillum. Est et sunt et proident officia nulla ut mollit eu
          officia quis aliqua non dolor. Culpa pariatur aliqua dolor ea irure
          incididunt occaecat.
          <br />
          <br />
          Aliqua voluptate laborum eiusmod eu aliqua laborum excepteur ullamco
          duis sint commodo consequat. Ipsum enim fugiat amet nulla est enim
          voluptate aliquip dolore aute officia deserunt sit laboris. Commodo in
          occaecat velit veniam. Nisi mollit do labore laborum. Enim deserunt
          sunt eu velit sunt pariatur voluptate veniam. Magna et commodo velit
          elit sint irure. Qui excepteur enim incididunt veniam velit.
        </p>
        <h3>Deleting your account</h3>
        <p>
          Ad cupidatat non duis fugiat nulla ea commodo ea commodo minim esse
          non. Quis mollit reprehenderit adipisicing dolor in excepteur sit
          Lorem ut veniam. Lorem nulla nisi aliqua sit. Consequat fugiat anim
          enim nisi laboris nulla est magna Lorem consectetur consectetur elit.
          Ad id adipisicing minim incididunt elit dolore nisi do esse sint ex
          sunt aliqua. Quis veniam magna fugiat amet.
          <br />
          <br />
          Est et magna ea mollit ipsum fugiat culpa fugiat laborum anim ipsum
          ipsum. Dolor velit deserunt deserunt cupidatat ad sunt do dolor velit
          excepteur sunt sit laborum cillum. Ad magna consectetur cupidatat ad
          aliqua minim tempor velit consequat duis ad id est. Eu ea aliqua
          veniam eu consequat sit duis culpa nisi excepteur occaecat. Magna
          mollit ex aliquip velit non irure sit. Irure sunt non laboris dolore
          qui velit dolore tempor ea qui enim id anim. Ut quis duis esse commodo
          laboris et aliquip sunt eiusmod voluptate enim velit eiusmod nisi. Ea
          ad labore cupidatat velit dolor cupidatat anim ad adipisicing nostrud
          esse cillum culpa deserunt. Sunt laboris sunt magna excepteur non.
          Officia quis tempor sunt proident et cillum laboris Lorem excepteur
          tempor mollit aliqua elit sit. Cupidatat eiusmod veniam anim fugiat in
          consectetur id eu labore adipisicing amet consectetur proident ipsum.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default Privacy;
