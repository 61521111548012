import React, { useState } from "react";
import "./FloatingNav.css";
import { SideTray } from "./SideTray";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const FloatingNav = ({
  currentChapter,
  novelChapters,
  handlePreviousChapter,
  handleNextChapter,
  onChapterChange,
}) => {
  const [isTrayOpen, setIsTrayOpen] = useState(false);

  const toggleTray = () => {
    setIsTrayOpen(!isTrayOpen);
  };

  return (
    <div>
      {/* Floating Navigation */}
      <div className="floating-nav">
        <button onClick={handleNextChapter}>
          <IoIosArrowUp />
        </button>
        <button onClick={toggleTray}>&#9776; </button>
        <button onClick={handlePreviousChapter}>
          <IoIosArrowDown />
        </button>
      </div>

      {/* Side Tray */}
      <SideTray
        isOpen={isTrayOpen}
        novelChapters={novelChapters}
        currentChapter={currentChapter}
        onChapterChange={onChapterChange}
        toggleTray={toggleTray}
      />
    </div>
  );
};

export default FloatingNav;
