import { useNavigate } from "react-router-dom";
import Loading from "../../../components/splash/loading/Loading";
import NoResult from "../../../components/splash/no-result/NoResult";
import Footer from "../../../components/layout/footer/Footer";
import Header from "../../../components/layout/header/Header";
import "./Genre.css";
import { useEffect, useState } from "react";
import { useFilterGenre, useGenre } from "../../../redux/actions/bookActions";
import truncateDescription from "../../../utils/truncate";
import { useGetGenres } from "../../../redux/actions/genresActions";
import defaultBookCover from "../../../assets/images/defaultBookCover.png";
import { FaEye } from "react-icons/fa";

function Genre() {
  const [loading, setLoading] = useState(true);
  const [selectedNav, setSelectedNav] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [selectedSort, setSelectedSort] = useState("popular");
  const [allGenres, setAllGenres] = useState([]);
  const [filteredGenres, setFilteredGenres] = useState([]);

  const navigate = useNavigate();
  const genres = useGetGenres();
  const genre = useGenre();
  const filterGenre = useFilterGenre();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await genres();

        if (response.payload && Array.isArray(response.payload.results)) {
          setAllGenres(response.payload.results);
        } else {
          console.log(response.message || "Failed to fetch genres");
        }
      } catch (error) {
        console.log(error.response?.message || error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    handleSelectNav("romance");
  }, []);

  const handleSelectNav = async (selectedGenre) => {
    try {
      setSelectedNav(selectedGenre);
      setLoading(true);
      const response = await genre(selectedGenre);

      if (response.payload && Array.isArray(response.payload.results)) {
        setFilteredGenres(response.payload.results);
        setSelectedNav(selectedGenre);
      } else {
        console.log(response.message || "Failed to fetch genre");
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterGenre = async (data) => {
    let _selectedSort = data.sort || selectedSort;
    let _selectedFilter = data.filter || selectedFilter;

    try {
      setSelectedFilter(_selectedFilter);
      setSelectedSort(_selectedSort);
      setLoading(true);
      const response = await filterGenre({
        genre: selectedNav,
        filter: _selectedFilter,
        sort: _selectedSort,
      });

      if (response.payload && Array.isArray(response.payload.results)) {
        setFilteredGenres(response.payload.results);
        setSelectedFilter(_selectedFilter);
        setSelectedSort(_selectedSort);
      } else {
        console.log(response.message || "Failed to filter genres");
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="genre">
      <div className="genre__section">
        <Header />
        <section className="genre__section__one">
          <div className="genre__section__one__nav">
            <h1>Genre</h1>
            <div className="genre__section__one__nav__options__wrap">
              <div className="genre__section__one__nav__options">
                {allGenres?.map((genre, index) => (
                  <h3
                    onClick={() =>
                      handleSelectNav(index === 0 ? "" : genre.name)
                    }
                    className={`genre__section__one__nav__options__h3 ${
                      selectedNav === (index === 0 ? "" : genre.name)
                        ? "active"
                        : ""
                    }`}
                    key={index}
                  >
                    {index === 0 ? "all" : genre.name}
                  </h3>
                ))}
              </div>
            </div>
          </div>
          <div className="genre__section__one__body">
            <div className="genre__section__one__nav__options__wrap">
              <div className="genre__section__one__body__div1">
                <h1>Filter By</h1>
                <span>
                  <h1
                    onClick={() => handleFilterGenre({ filter: "all" })}
                    className={`${
                      selectedFilter === "all" ? "genre__nav__active" : ""
                    }`}
                  >
                    All
                  </h1>
                  <h1
                    onClick={() => handleFilterGenre({ filter: "ongoing" })}
                    className={`${
                      selectedFilter === "ongoing" ? "genre__nav__active" : ""
                    }`}
                  >
                    Ongoing
                  </h1>
                  <h1
                    onClick={() => handleFilterGenre({ filter: "completed" })}
                    className={`${
                      selectedFilter === "completed" ? "genre__nav__active" : ""
                    }`}
                  >
                    Completed
                  </h1>
                </span>
              </div>
            </div>

            <div className="genre__section__one__body__div1">
              <h1>Sort By</h1>
              <span>
                <h1
                  onClick={() => handleFilterGenre({ sort: "popular" })}
                  className={`${
                    selectedSort === "popular" ? "genre__nav__active" : ""
                  }`}
                >
                  Popular
                </h1>
                <h1
                  onClick={() => handleFilterGenre({ sort: "recommended" })}
                  className={`${
                    selectedSort === "recommended" ? "genre__nav__active" : ""
                  }`}
                >
                  Recommended
                </h1>
                <h1
                  onClick={() => handleFilterGenre({ sort: "rating" })}
                  className={`${
                    selectedSort === "rating" ? "genre__nav__active" : ""
                  }`}
                >
                  Rating
                </h1>
              </span>
            </div>
            <div className="genre__section__one__body__display">
              {loading ? (
                <Loading />
              ) : filteredGenres.length === 0 ? (
                <NoResult />
              ) : (
                filteredGenres.map((item, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => navigate(`/book/${item.id}`)}
                      className="genre__section__one__body__display__block"
                    >
                      <img
                        alt=""
                        src={
                          item?.cover_image
                            ? item.cover_image
                            : defaultBookCover
                        }
                      />
                      <div className="genre__section__one__display__block__about">
                        <h1>{item.title && item.title}</h1>
                        <span>
                          <h1>{item.author && item.author.name}</h1>{" "}
                          <h2>
                            <FaEye />
                            {item.num_views && item.num_views}
                          </h2>
                        </span>
                        <p>
                          {item.description &&
                            truncateDescription(item.description, 90)}
                        </p>

                        <article>
                          {item.tags.map((item, i) => {
                            return <h1 key={i}>{item}</h1>;
                          })}
                        </article>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Genre;
