import { GiPadlock } from "react-icons/gi";

export const SideTray = ({
  isOpen,
  novelChapters,
  currentChapter,
  onChapterChange,
  toggleTray,
}) => {
  return (
    <div className={`side-tray ${isOpen ? "open" : ""}`}>
      <button onClick={toggleTray} className="close-btn">
        &#10005;
      </button>
      <h4>Contents</h4>
      <div className="side-tray-chapters-wrap">
        <ul>
          {novelChapters?.map((chapter, index) => (
            <li
              key={index}
              onClick={() => onChapterChange(parseFloat(chapter.chapter))}
              className={`side-tray-chapters-wrap-li ${
                parseFloat(chapter.chapter) == currentChapter ? "active" : ""
              }`}
            >
              {`Chapter ${parseFloat(chapter.chapter)}: ${chapter.title}`}

              {chapter.unlock_mode == "F" && <GiPadlock />}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
