import React, { useState } from "react";
import "./create-novel-modal.css"; // Import the custom CSS

const Modal = ({
  sections,
  isOpen,
  onClose,
  onSelectChip,
  selectedChips,
  onConfirm,
}) => {
  const [activeSection, setActiveSection] = useState(0);

  if (!isOpen) return null;

  return (
    <div className="modal-container" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="section-tabs">
          {sections.map((section, index) => (
            <button
              type="button"
              key={index}
              className={`section-tab ${
                index === activeSection ? "active" : ""
              }`}
              onClick={() => setActiveSection(index)}
            >
              {section.title}
            </button>
          ))}
        </div>
        <div className="chip-container">
          {sections[activeSection].chips.map((chip) => (
            <div
              key={chip.id}
              className={`chip ${chip.selected ? "selected" : ""}`}
              onClick={() => onSelectChip(chip)}
            >
              {chip.label}
            </div>
          ))}
        </div>
        <div className="selected-chips-list">
          <h4>Selected Tags:</h4>
          {selectedChips.map((chip) => {
            {
              /* console.log(chip); */
            }
            return (
              <div key={chip.id} className="chip selected">
                {chip.label}
              </div>
            );
          })}
        </div>
        <div className="modal-buttons">
          <button
            type="button"
            className="modal-button confirm"
            onClick={onConfirm}
          >
            Confirm
          </button>
          <button
            type="button"
            className="modal-button cancel"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
