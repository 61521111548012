import { useState } from "react";
import "./AccountInbox.css";

function AccountInbox() {
  const [show, setShow] = useState(false);

  const showMessage = () => {
    setShow(true);
  };
  return (
    <div className="ac__inbox">
      <h1>Inbox</h1>
      <section className="ac__inbox__section__one">
        <div
          className={`ac__inbox__section__one__chats${show ? " active" : ""}`}
        >
          <span
            className="ac__inbox__section__one__chats__entries"
            onClick={showMessage}
          >
            <p className="ac__inbox__section__one__chats__entries__sender">
              Admin
            </p>
            <p className="ac__inbox__section__one__chats__entries__header">
              Bonreader new feature - v88.0
            </p>
          </span>
          <span
            className="ac__inbox__section__one__chats__entries"
            onClick={showMessage}
          >
            <p className="ac__inbox__section__one__chats__entries__sender">
              Admin
            </p>
            <p className="ac__inbox__section__one__chats__entries__header">
              Withdrawal request update
            </p>
          </span>
        </div>
        <div
          className={`ac__inbox__section__one__chat__message${
            show ? " active" : ""
          }`}
        >
          <h3>Bonreader new feature - v88.0</h3>
          <p>
            1. All users' WebNovel profile&background pictures will be verified
            by machines**, violation pictures will fail to get uploaded. 2. All
            users can now report and block readers on their profile page.
            **After blocking, book reviews/chapter& paragraph comments/booklist
            comments posted by the blocked user are not visible to the user who
            initiated the block.(This function is still in beta, we will improve
            it in the future for authors, for example, authors will no longer
            receive message notifications from blocked users) 3. All users can
            now report book covers violating WebNovel Content Creation Rules**
            on book detail page 4.All users can now report book chapter
            plagiarism/repeated/nonsense issues section
          </p>
        </div>
      </section>
    </div>
  );
}

export default AccountInbox;
