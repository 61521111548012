import logo from "../../../../assets/icons/logo.png";
import "./Header.css";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();

  return (
    <div className="sup__header">
      <img
        className="sup__header__logo"
        src={logo}
        alt=""
        onClick={() => navigate("/")}
      />
    </div>
  );
}

export default Header;
