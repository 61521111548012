import { useEffect, useState } from "react";
import "./edit-book.css";
import NovelModal from "../create-novel/createNovelModal";
import {
  useCreateNovel,
  useGetNovel,
  usePatchNovel,
} from "../../../redux/actions/bookActions";
import toastManager from "../../../components/toast/ToasterManager";
import ClipLoader from "react-spinners/ClipLoader";
import { MdFilePresent } from "react-icons/md";
import { useGetTags } from "../../../redux/actions/tagsActions";
import { useGetGenres } from "../../../redux/actions/genresActions";
import { useNavigate, useParams } from "react-router-dom";

function EditBook() {
  const { id } = useParams();
  const navigate = useNavigate();
  const patchNovel = usePatchNovel();
  const getNovel = useGetNovel();
  const genres = useGetGenres();
  const tags = useGetTags();
  const createNovel = useCreateNovel();
  const [novel, setNovel] = useState(null);
  const [allGenres, setAllGenres] = useState([]);
  const [tagsArray, setTagsArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState("");
  const [previousTags, setPreviousTags] = useState([]);

  const [formData, setFormData] = useState({
    image: null,
    bookTitle: "",
    language: "",
    leadingGender: "",
    genres: [],
    description: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedChips, setSelectedChips] = useState([]);
  const [chipsData, setChipsData] = useState([]);

  // Handle change for genres (multiple select)
  const handleGenreChange = (e) => {
    const selectedGenre = e.target.value;

    // Check if the genre is already selected
    if (formData.genres.includes(selectedGenre)) {
      // If the genre is already selected, remove it
      setFormData({
        ...formData,
        genres: formData.genres.filter((genre) => genre !== selectedGenre),
      });
    } else {
      // If the genre is not selected, add it to the list
      setFormData({
        ...formData,
        genres: [...formData.genres, selectedGenre],
      });
    }
  };

  const handleChipSelect = (chip) => {
    setSelectedChips((prev) =>
      prev.includes(chip)
        ? prev.filter((_chip) => _chip.id !== chip.id)
        : [...prev, chip]
    );
  };

  const handleConfirm = () => {
    setIsModalOpen(false);
  };

  const handleTags = async () => {
    try {
      setLoading(true);
      const response = await tags();

      if (response.payload && Array.isArray(response.payload.results)) {
        const data = response.payload.results;
        setTagsArray(data);

        // Step 1: Group by category
        const groupedByCategory = data.reduce((acc, item) => {
          if (!acc[item.category]) {
            acc[item.category] = [];
          }
          acc[item.category].push(item);
          return acc;
        }, {});

        // Step 2: Map to desired structure
        const transformedData = Object.keys(groupedByCategory).map(
          (categoryId) => ({
            title: `Category ${categoryId}`, // Customize title as needed
            chips: groupedByCategory[categoryId].map((item) => ({
              id: item.id,
              label: item.name,
              selected: selectedChips.includes(item.id),
            })),
          })
        );

        setChipsData(transformedData);
      } else {
        console.log(response.message || "Failed to fetch shelf");
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchGenres = async () => {
    try {
      setLoading(true);
      const response = await genres();

      if (response.payload && Array.isArray(response.payload.results)) {
        setAllGenres(response.payload.results);
      } else {
        console.log(response.message || "Failed to fetch genres");
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    const selectedTags = selectedChips.map((item) => item.label);

    try {
      setLoading(true);

      const response = await patchNovel({
        novelId: id,
        title: formData.bookTitle,
        language: formData.language,
        leading_gender: formData.leadingGender,
        description: formData.description,
        genres: formData.genres,
        tags: selectedTags,
      });

      if (response.payload && response.meta.requestStatus == "fulfilled") {
        setFormData({
          image: null,
          bookTitle: "",
          language: "",
          leadingGender: "",
          genres: [],
          description: "",
        });
        setFileName(null);
        setImage(null);
        setSelectedChips([]);
        setPreviousTags([]);
        toastManager.addToast({
          message: "Novel edited succesfully",
          type: "success",
        });
        navigate("/account-stories");

        return;
      } else {
        toastManager.addToast({
          message: "Novel edit failed",
          type: "error",
        });
      }
    } catch (error) {
      toastManager.addToast({
        message: error.response?.message || "Novel edit failed",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGetNovel = async () => {
    try {
      setLoading(true);
      const response = await getNovel(id);

      if (response.payload) {
        setNovel(response.payload);
        setFormData({
          image: response.payload.cover_image,
          bookTitle: response.payload.title,
          language: response.payload.language,
          leadingGender: response.payload.leading_gender,
          genres: response.payload.genres,
          description: response.payload.description,
        });
        setImage(response.payload.cover_image);
        setPreviousTags(response.payload.tags);
      } else {
        console.log(response.message || "Failed to fetch novel");
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetNovel();
    handleTags();
    fetchGenres();
  }, []);

  if (!novel)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff6ef",
          minHeight: "100svh",
        }}
      >
        <ClipLoader color="#669933" size={40} />{" "}
      </div>
    );

  return (
    <div className="create__bg">
      <div className="create">
        <section className="edit__book">
          <form onSubmit={handleEdit}>
            <div className="edit__book__upload">
              <div>
                <label for="bookTitle">Book Cover </label>

                <div
                  className="image__upload"
                  onClick={() =>
                    document.querySelector(".input__field").click()
                  }
                >
                  <input
                    name="image"
                    type="file"
                    accept="image/*"
                    className="input__field"
                    hidden
                    disabled
                  />

                  {formData.image ? (
                    <img
                      src={image}
                      width={75}
                      height={75}
                      alt={fileName}
                      className="input__preview"
                    />
                  ) : (
                    <MdFilePresent color="#669933" size={50} />
                  )}
                </div>
                <input
                  type="file"
                  id="bookCover"
                  name="bookCover"
                  accept=".jpg,.png"
                />
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginTop: "10px",
                  }}
                >
                  * Cover image cannot be edited after creation
                </p>
              </div>
            </div>
            <span className="edit__book__form__input__block entire--span">
              <label for="bookTitle">Book Title</label>
              <input
                name="bookTitle"
                value={formData.bookTitle}
                type="text"
                id="bookTitle"
                disabled
                onChange={handleChange}
              />
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  marginTop: "10px",
                }}
              >
                * Book title cannot be edited after creation
              </p>
            </span>
            <span className="edit__book__form__input__block">
              <label for="language">Language</label>
              <select
                required
                name="language"
                id="language"
                value={formData.language}
                onChange={handleChange}
                className="modal__apply__input"
              >
                <option value={null}>--</option>
                <option value="en">English</option>
              </select>
            </span>
            <span className="edit__book__form__input__block">
              <label for="leadingGender">Leading Gender</label>
              <select
                required
                name="leadingGender"
                id="leadingGender"
                value={formData.leadingGender}
                onChange={handleChange}
                className="modal__apply__input"
              >
                <option value={null}>--</option>
                <option value="M">Male</option>
                <option value="F">Female</option>
              </select>
            </span>{" "}
            <span className="edit__book__form__input__block">
              <label for="genres">Genres</label>
              <select
                required
                name="genres"
                id="genres"
                value={formData.genres}
                onChange={handleGenreChange}
                className="modal__apply__input"
              >
                <option value={null}>--</option>
                {allGenres.map((genre, id) => (
                  <option key={id} value={genre.name}>
                    {genre.name}
                  </option>
                ))}
              </select>
            </span>
            {/* Display selected genres */}
            <div style={{ marginBottom: "20px" }}>
              {formData.genres?.length > 0 ? (
                <ul
                  style={{
                    listStyleType: "none",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  {formData.genres.map((genre, index) => (
                    <li
                      key={index}
                      style={{
                        padding: "10px 15px",
                        borderRadius: "30px",
                        backgroundColor: "#66993322",
                        color: "#669933",
                        fontSize: "12px",
                      }}
                    >
                      {genre}
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
            <span className="edit__book__form__input__block">
              <label for="tags">Tags</label>
              <button
                type="button"
                className="novel-modal-select-button"
                onClick={() => setIsModalOpen(true)}
              >
                {previousTags?.length === 0
                  ? "Select Chips"
                  : previousTags?.map((chip) => {
                      return (
                        <div
                          key={chip.id}
                          className="chip selected"
                          style={{
                            background: "#f5863433",
                            color: "#f58634",
                          }}
                        >
                          {chip}
                        </div>
                      );
                    })}
                {selectedChips?.length === 0
                  ? "Select Chips"
                  : selectedChips?.map((chip) => {
                      return (
                        <div key={chip.id} className="chip selected">
                          {chip.label}
                        </div>
                      );
                    })}
              </button>
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  marginTop: "10px",
                }}
              >
                * Previous tags (Indicated by orange theme) will not be added
                automatically. Select all the tags you want irrespective of
                whether it is a previous tag. Selected tags are the chips
                displayed with green theme.
              </p>
              <NovelModal
                sections={chipsData}
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSelectChip={handleChipSelect}
                selectedChips={selectedChips}
                onConfirm={handleConfirm}
              />
            </span>
            <span className="edit__book__form__input__block ">
              <label for="description">Synopsis</label>
              <textarea
                required
                name="description"
                id="description"
                value={formData.description}
                onChange={handleChange}
                className="edit__book__form__input__block__textarea"
              ></textarea>
            </span>
            <button disabled={loading} type="submit">
              {loading ? <ClipLoader color="#fff" size={20} /> : "Edit novel"}
            </button>
          </form>
        </section>
      </div>
    </div>
  );
}

export default EditBook;
