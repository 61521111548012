import { BiWallet } from "react-icons/bi";

import "./Account.dashboard.css";
import image1 from "../../../assets/account.dashboard.png";
import coin from "../../../assets/icons/coin.png";
import defaultCoverImage from "../../../assets/images/defaultBookCover.png";
import { BsStack } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Modal from "../../../components/modal/Modal";
import { useGetMyNovels } from "../../../redux/actions/bookActions";
import Loading from "../../../components/splash/loading/Loading";
import "../../../utils/skeleton/account-dashboard-skeleton.css";
import toastManager from "../../../components/toast/ToasterManager";

function AccountDashboard() {
  const getMyNovels = useGetMyNovels();
  const [myNovels, setMyNovels] = useState(null);
  const [displayNovel, setDisplayNovel] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState({
    buy: false,
  });
  const closeModal = () => {
    setIsOpen({
      buy: false,
    });
  };
  const handleModalClick = (option) => {
    option === "buy"
      ? setIsOpen((prev) => ({ ...prev, buy: true }))
      : closeModal();
  };

  const handleChangeDisplay = (id) => {
    setDisplayNovel(myNovels.find((novel) => novel.id == id));
  };

  const handleGetNovels = async () => {
    try {
      setLoading(true);
      const response = await getMyNovels();

      console.log("myNovels", response);

      if (response.payload) {
        setMyNovels(response.payload.results);
        setDisplayNovel(response.payload.results[0]);
      } else {
        console.log(response.message || "Failed to fetch shelf");
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetNovels();
  }, []);

  return (
    <div className="ac__dashboard">
      <section className="ac__dashboard__section__one">
        {loading && (
          // Render skeleton while loading
          <div className="account-dashboard-skeleton account-dashboard-skeleton-tab"></div>
        )}
        {!loading && (
          <div className="ac__dashboard__section__one__div">
            <span>
              <h1>Wallet</h1>
              <BiWallet className="ac__dashboard__section__one__div__icon" />
            </span>
            <span>
              <h1>3000</h1>
              <button onClick={() => handleModalClick("buy")}>
                Top up coins
              </button>
            </span>
          </div>
        )}
        {loading && (
          // Render skeleton while loading
          <div className="account-dashboard-skeleton account-dashboard-skeleton-tab"></div>
        )}
        {!loading && (
          <div className="ac__dashboard__section__one__div">
            <span>
              <h1>Total balalnce</h1>
              <BiWallet className="ac__dashboard__section__one__div__icon" />
            </span>
            <span>
              <h1>$800</h1>
              <button>Payment details</button>
            </span>
          </div>
        )}
        {loading && (
          // Render skeleton while loading
          <div className="account-dashboard-skeleton account-dashboard-skeleton-tab"></div>
        )}
        {!loading && (
          <div className="ac__dashboard__section__one__div">
            <span>
              <h1>Total revenue</h1>
              <BiWallet className="ac__dashboard__section__one__div__icon" />
            </span>
            <span>
              <h1>$30000</h1>
            </span>
          </div>
        )}
        {loading && (
          // Render skeleton while loading
          <div className="account-dashboard-skeleton account-dashboard-skeleton-tab"></div>
        )}
        {!loading && (
          <div className="ac__dashboard__section__one__div">
            <span>
              <h1>Available income</h1>
              <BiWallet className="ac__dashboard__section__one__div__icon" />
            </span>
            <span>
              <h1>$300</h1>
              <button>Withdraw</button>
            </span>
          </div>
        )}
      </section>
      <section className="ac__dashboard__section__two">
        <span>
          <p>My Novels</p>
          <BsStack className="ac__dashboard__section__two__icon" />
        </span>
        {loading ? (
          <Loading />
        ) : (
          <>
            {myNovels == null && (
              <>
                <img alt="" src={image1} />
                <p>
                  Get Started Today, Start Writing and Get your Book to Waiting
                  Readers!
                </p>
                <button onClick={() => navigate("/create-novel")}>
                  New Story
                </button>
              </>
            )}
            {myNovels?.length > 0 && (
              <>
                <div className="ac__dashboard__section__two__block">
                  <img
                    src={
                      displayNovel?.cover_image
                        ? displayNovel?.cover_image
                        : defaultCoverImage
                    }
                    alt=""
                  />
                  <div className="ac__dashboard__section__two__block__detail">
                    <span className="ac__dashboard__section__two__block__detail__header">
                      <h1>{displayNovel?.title}</h1>
                      <select
                        required
                        name="language"
                        id="language"
                        className="ac__dashboard__section__two__block__detail__select"
                        onChange={(e) => handleChangeDisplay(e.target.value)}
                      >
                        {myNovels.map((novel, id) => (
                          <option key={id} value={novel.id}>
                            {novel.title}
                          </option>
                        ))}
                      </select>
                    </span>
                    <span className="ac__dashboard__section__two__block__detail__content">
                      <article>
                        <p>Collections</p>
                        <h1>{displayNovel?.num_collections}</h1>
                      </article>
                      <article>
                        <p>Reviews</p>
                        <h1>{displayNovel?.num_reviews}</h1>
                      </article>
                      <article>
                        <p>Chapters</p>
                        <h1>{displayNovel?.num_chapters}</h1>
                      </article>
                      <article>
                        <p>Words</p>
                        <h1>{displayNovel?.num_words}</h1>
                      </article>
                      <article>
                        <p>Status</p>
                        <h1>{`${
                          displayNovel?.completed ? "Completed" : "Ongoing"
                        }`}</h1>
                      </article>
                    </span>
                  </div>
                </div>
                <div className="ac__dashboard__section__two__wrap">
                  <button
                    onClick={() =>
                      navigate(`/book-chapters/${displayNovel.id}`)
                    }
                    className="ac__dashboard__section__two__wrap__button one"
                  >
                    New Chapter
                  </button>
                  <button
                    onClick={() => {
                      if (displayNovel.num_words < 5000) {
                        toastManager.addToast({
                          message:
                            "You can only for contract when you have written over 5000 words",
                          type: "error",
                        });
                        return;
                      }
                      navigate("/contract");
                    }}
                    className="ac__dashboard__section__two__wrap__button two"
                  >
                    Apply for Contract
                  </button>
                  <button
                    onClick={() => navigate("/create-novel")}
                    className="ac__dashboard__section__two__wrap__button three"
                  >
                    New Story
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </section>

      <Modal isOpen={isOpen.buy} onClose={closeModal}>
        <div className="ac__modal__buy">
          <h1>TOP UP COINS</h1>
          <p>Coins can be used to unlock chapters and buy gifts for authors.</p>
          <div className="ac__modal__buy__options">
            <div className="ac__modal__buy__option">
              <span>
                <p>100</p>
                <img src={coin} alt="coin" />
              </span>
              <h3>$0.99</h3>
            </div>
            <div className="ac__modal__buy__option">
              <span>
                <p>300</p>
                <img src={coin} alt="coin" />
              </span>
              <h3>$0.99</h3>
            </div>
            <div className="ac__modal__buy__option">
              <span>
                <p>500</p>
                <img src={coin} alt="coin" />
              </span>
              <h3>$0.99</h3>
            </div>
            <div className="ac__modal__buy__option">
              <span>
                <p>1000</p>
                <img src={coin} alt="coin" />
              </span>
              <h3>$0.99</h3>
            </div>
            <div className="ac__modal__buy__option">
              <span>
                <p>2000</p>
                <img src={coin} alt="coin" />
              </span>
              <h3>$0.99</h3>
            </div>
            <div className="ac__modal__buy__option">
              <span>
                <p>5000</p>
                <img src={coin} alt="coin" />
              </span>
              <h3>$0.99</h3>
            </div>
            <div className="ac__modal__buy__option">
              <span>
                <p>10000</p>
                <img src={coin} alt="coin" />
              </span>
              <h3>$0.99</h3>
            </div>
          </div>
          <button> PAY NOW </button>
        </div>
      </Modal>
    </div>
  );
}

export default AccountDashboard;
