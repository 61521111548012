import { FaThumbsUp, FaThumbsDown, FaBookBookmark } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { ImBookmarks } from "react-icons/im";
import { FaEye, FaTags } from "react-icons/fa";
import { AiFillTrademarkCircle } from "react-icons/ai";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import "./Book.css";
import Footer from "../../../components/layout/footer/Footer";
import Header from "../../../components/layout/header/Header";
import defaultBookCover from "../../../assets/images/defaultBookCover.png";
import image2 from "../../../assets/images/book-gift.png";
import coin from "../../../assets/icons/coin.png";
import gift1 from "../../../assets/icons/gift1.png";
import gift2 from "../../../assets/icons/gift2.png";
import gift3 from "../../../assets/icons/gift3.png";
import gift4 from "../../../assets/icons/gift4.png";
import gift5 from "../../../assets/icons/gift5.png";
import gift6 from "../../../assets/icons/gift6.png";
import gift7 from "../../../assets/icons/gift7.png";
import gift8 from "../../../assets/icons/gift8.png";
import image4 from "../../../assets/image10.png";
import { BsStarFill } from "react-icons/bs";
import Modal from "../../../components/modal/Modal";
import {
  useGetNovel,
  useGetNovelChapters,
  useGetRecommendedNovels,
} from "../../../redux/actions/bookActions";
import toastManager from "../../../components/toast/ToasterManager";
import ToasterContainer from "../../../components/toast/ToasterContainer";
import { ClipLoader } from "react-spinners";
import NoResult from "../../../components/splash/no-result/NoResult";
import { useAddToShelf } from "../../../redux/actions/shelfActions";
import formatDateFromIso from "../../../utils/formatDateFromISo";
import { useAuth } from "../../../redux/actions/authActions";
import "../../../utils/skeleton/book-skeleton.css";

function Book() {
  const auth = useAuth();
  const navigate = useNavigate();
  const getRecommendedNovels = useGetRecommendedNovels();
  const addToShelf = useAddToShelf();
  const getNovel = useGetNovel();
  const getNovelChapters = useGetNovelChapters();

  const { id } = useParams();
  const [novel, setNovel] = useState(null);
  const [novelChapters, setNovelChapters] = useState(null);
  const [recomendations, setRecommendations] = useState([]);
  const [reviews, setReviews] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingShelf, setLoadingShelf] = useState(false);
  const average = 4;
  const [select, setSelect] = useState({
    select1: true,
    select2: false,
  });
  const [isOpen, setIsOpen] = useState({
    review: false,
    gift: false,
  });

  const closeModal = () => {
    setIsOpen({
      review: false,
      gift: false,
    });
  };

  const handleModalClick = (option) => {
    option === "review"
      ? setIsOpen((prev) => ({ ...prev, gift: false, review: true }))
      : setIsOpen((prev) => ({ ...prev, gift: true, review: false }));
  };

  const handleGetNovelChapters = async () => {
    try {
      setLoading(true);
      const response = await getNovelChapters(id);

      if (response.payload) {
        setNovelChapters(response.payload.results);
      } else {
        console.log(response.message || "Failed to fetch shelf");
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGetNovel = async (id) => {
    try {
      setLoading(true);
      const response = await getNovel(id);

      if (response.payload) {
        setNovel(response.payload);
      } else {
        console.log(response.message || "Failed to fetch book");
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddToShelf = async () => {
    if (!auth.user) {
      toastManager.addToast({
        message: "You need to login to add a novel to your shelf",
        type: "warning",
      });
      return;
    }

    try {
      setLoadingShelf(true);
      const response = await addToShelf(id);

      if (response.payload) {
        toastManager.addToast({
          message: "Novel added to shelf",
          type: "success",
        });
      } else {
        toastManager.addToast({
          message: "Error adding to shelf",
          type: "error",
        });
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoadingShelf(false);
    }
  };

  const handleGetRecommendations = async (id) => {
    setLoading(true);

    try {
      const response = await getRecommendedNovels(id);
      if (response.payload) {
        setRecommendations(response.payload.results);
      } else {
        console.error(response.message || "Failed to fetch recommendations");
      }
    } catch (e) {
      console.log(e.response?.message || e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleGetNovelChapters(id);
      handleGetNovel(id);
      handleGetRecommendations(id);
    }
  }, [id]);

  return (
    <div className="book__bg">
      <ToasterContainer />
      <div className="book">
        <Header />
        <div className="book__section__one">
          {loading && (
            // Render skeleton while loading
            <h1 className="book-skeleton book-skeleton-cover-image"></h1>
          )}
          {!loading && (
            <img
              src={novel?.cover_image ? novel.cover_image : defaultBookCover}
              alt=""
            />
          )}
          <div className="book__section__one__end">
            {loading && (
              // Render skeleton while loading
              <h1 className="book-skeleton book-skeleton-title"></h1>
            )}
            {!loading && <h1>{novel?.title}</h1>}
            {loading && (
              // Render skeleton while loading
              <span className="book-skeleton book-skeleton-span"></span>
            )}
            {!loading && (
              <span>
                <FaBookBookmark />
                <h3>{novel?.genres?.[0] || "Unknown Genre"}</h3>
              </span>
            )}
            {loading && (
              // Render skeleton while loading
              <span className="book-skeleton book-skeleton-span"></span>
            )}
            {!loading && (
              <span>
                <ImBookmarks />
                <h3>{novel?.num_chapters || 0} Chapters</h3>
              </span>
            )}
            {/* {loading && (
              // Render skeleton while loading
              <span className="book-skeleton book-skeleton-span"></span>
            )}
            {!loading && (
              <span>
                <AiFillTrademarkCircle />
                <h3>{novel?.age_rating || "Unknown"}+</h3>
              </span>
            )} */}
            {loading && (
              // Render skeleton while loading
              <span className="book-skeleton book-skeleton-span"></span>
            )}
            {!loading && (
              <span>
                <FaTags />
                <h3>{novel?.author?.name || "Unknown Author"}</h3>
              </span>
            )}
            {loading && (
              // Render skeleton while loading
              <span className="book-skeleton book-skeleton-span"></span>
            )}
            {!loading && (
              <span>
                <FaEye />
                <h3>{`${novel?.num_views || 0} Views`}</h3>
              </span>
            )}

            <span className="book__section__one__end__cta">
              {loading && (
                // Render skeleton while loading
                <h1 className="book-skeleton book-skeleton-button"></h1>
              )}
              {!loading && (
                <button
                  className="book__section__one__end__cta__bt read"
                  onClick={
                    novelChapters?.length === 0
                      ? () =>
                          toastManager.addToast({
                            message: "No chapters available for this novel",
                            type: "error",
                          })
                      : () => navigate(`/book-page/${id}`)
                  }
                >
                  READ
                </button>
              )}
              {loading && (
                // Render skeleton while loading
                <h1 className="book-skeleton book-skeleton-button"></h1>
              )}
              {!loading && (
                <button
                  className="book__section__one__end__cta__bt add"
                  onClick={handleAddToShelf}
                  disabled={loading}
                >
                  {loadingShelf ? (
                    <ClipLoader color="#fff" size={15} />
                  ) : (
                    "ADD TO SHELF"
                  )}
                </button>
              )}
              {/* {loading && (
                // Render skeleton while loading
                <h1 className="book-skeleton book-skeleton-button"></h1>
              )}
              {!loading && (
                <button className="book__section__one__end__cta__bt like">
                  <FaThumbsUp />
                  Like
                </button>
              )} */}
            </span>
          </div>
        </div>
        <div className="book__section__two">
          <span className="book__section__two__menu">
            {loading && (
              // Render skeleton while loading
              <h1 className="book-skeleton book-skeleton-button"></h1>
            )}
            {!loading && (
              <button
                className={
                  select.select1
                    ? "book__section__two__menu__button--active"
                    : "book__section__two__menu__button"
                }
                onClick={() =>
                  setSelect((prevState) => ({
                    ...prevState,
                    select1: true,
                    select2: false,
                  }))
                }
              >
                About
              </button>
            )}

            {loading && (
              // Render skeleton while loading
              <h1 className="book-skeleton book-skeleton-button"></h1>
            )}
            {!loading && (
              <button
                className={
                  select.select2
                    ? "book__section__two__menu__button--active"
                    : "book__section__two__menu__button"
                }
                onClick={() =>
                  setSelect((prevState) => ({
                    ...prevState,
                    select1: false,
                    select2: true,
                  }))
                }
              >
                Catalog
              </button>
            )}
          </span>
          <>
            {select.select1 && (
              <div>
                <div className="book__section__two__synopsis">
                  {loading && (
                    // Render skeleton while loading
                    <h1 className="book-skeleton book-skeleton-title"></h1>
                  )}
                  {!loading && <h1>Synopsis</h1>}
                  {loading && (
                    // Render skeleton while loading
                    <h1 className="book-skeleton book-skeleton-synopsis"></h1>
                  )}
                  {!loading && (
                    <p>{novel?.description || "No synopsis available."}</p>
                  )}
                </div>
                <div className="book__section__two__tags">
                  {loading && (
                    // Render skeleton while loading
                    <h1 className="book-skeleton book-skeleton-title"></h1>
                  )}
                  {!loading && <h1>Tags</h1>}
                  {loading && (
                    // Render skeleton while loading
                    <h1 className="book-skeleton book-skeleton-button"></h1>
                  )}
                  {!loading && (
                    <span>
                      {novel?.tags?.length > 0
                        ? novel.tags.map((tag, i) => {
                            return <button key={i}>{tag}</button>;
                          })
                        : "No tags available."}
                    </span>
                  )}
                </div>
                <div className="book__section__two__gifts">
                  {loading && (
                    // Render skeleton while loading
                    <h1 className="book-skeleton book-skeleton-title"></h1>
                  )}
                  {!loading && <h1>Gifts</h1>}
                  {loading && (
                    // Render skeleton while loading
                    <h1 className="book-skeleton book-skeleton-title"></h1>
                  )}
                  {!loading && (
                    <span className="book__section__two__gifts__span">
                      <span>
                        <img alt="" src={image2} /> <h1>12</h1>
                        <p>received</p>
                      </span>
                      <button onClick={() => handleModalClick("gift")}>
                        GIFT AUTHOR
                      </button>
                    </span>
                  )}
                </div>
                {loading && (
                  // Render skeleton while loading
                  <h1 className="book-skeleton book-skeleton-description"></h1>
                )}
                {!loading && (
                  <div className="book__section__two__ratings">
                    <div className="book__section__two__ratings__start">
                      <div>
                        <h3>Writing quality</h3>
                        <span>
                          {Array(Math.round(average))
                            .fill()
                            .map((_, i) => {
                              return <BsStarFill key={i} />;
                            })}
                        </span>
                      </div>

                      <div>
                        <h3>Stability of updates</h3>
                        <span>
                          {Array(Math.round(average))
                            .fill()
                            .map((_, i) => {
                              return <BsStarFill key={i} />;
                            })}
                        </span>
                      </div>
                      <div>
                        <h3>Story Development</h3>
                        <span>
                          {Array(Math.round(average))
                            .fill()
                            .map((_, i) => {
                              return <BsStarFill key={i} />;
                            })}
                        </span>
                      </div>
                      <div>
                        <h3>Character Design</h3>
                        <span>
                          {Array(Math.round(average))
                            .fill()
                            .map((_, i) => {
                              return <BsStarFill key={i} />;
                            })}
                        </span>
                      </div>
                      <div>
                        <h3>World background</h3>
                        <span>
                          {Array(Math.round(average))
                            .fill()
                            .map((_, i) => {
                              return <BsStarFill key={i} />;
                            })}
                        </span>
                      </div>
                    </div>
                    <div className="book__section__two__ratings__end">
                      <span>
                        {Array(Math.round(average))
                          .fill()
                          .map(() => {
                            return <BsStarFill />;
                          })}
                      </span>
                      <p>
                        <b>8.0</b>(9012 Reviews)
                      </p>
                      <button
                        className="book__section__two__ratings__end__bt"
                        onClick={() => handleModalClick("review")}
                      >
                        DROP A REVIEW
                      </button>
                    </div>
                  </div>
                )}
                <div className="book__section__two__recommendations">
                  {recomendations?.length > 0 && (
                    <>
                      <h1>You may also like</h1>
                      <div className="book__section__two__recommendations__wrap">
                        <span className="book__section__two__recommendations__span">
                          {recomendations.map(
                            (recomendation, i) =>
                              i < 7 && (
                                <div key={i}>
                                  <img
                                    alt=""
                                    src={
                                      recomendation?.cover_image
                                        ? recomendation.cover_image
                                        : defaultBookCover
                                    }
                                    onClick={() =>
                                      navigate(`/book/${recomendation.id}`)
                                    }
                                  />
                                  <h1>{recomendation.title}</h1>
                                  <span>
                                    {/* <h3>Genre</h3> */}
                                    <h4>{recomendation.genres[0]}</h4>
                                  </span>
                                </div>
                              )
                          )}
                        </span>
                      </div>
                    </>
                  )}
                </div>

                <div className="book__section__two__reviews">
                  {loading && (
                    // Render skeleton while loading
                    <h1 className="book-skeleton book-skeleton-title"></h1>
                  )}
                  {!loading && <h1>Reviews</h1>}

                  {!loading && (
                    <div>
                      {reviews?.length === 0 || reviews === null ? (
                        <NoResult
                          header="No Reviews Found"
                          content="No reviewss found for this novel"
                        />
                      ) : (
                        <>
                          {reviews?.map((review, i) => (
                            <div key={i}>
                              <h1>FAHRENHEIT_RM</h1>
                              <p>
                                I've been reading this book for a while now, and
                                it's amazing! although my words can't do this
                                work of art justice I'll try my best: The
                                characters feel so real and the author does an
                                amazing job in making all of the magical laws
                                make sense. when the protagonist becomes more
                                powerful it isn't in some vague way that he is
                                suddenly "stronger" but he gains some new thing
                                through hard work. it makes the story that much
                                more impressive because it feels like he is just
                                describing how another world works rather than
                                some make belive thing!
                              </p>
                              <span>
                                <h3>12-09-2023 10:00AM</h3>
                                <span>
                                  <FaThumbsUp className="book__section__two__reviews__thumbsup" />
                                  <p>23</p>
                                  <FaThumbsDown className="book__section__two__reviews__thumbsdown" />
                                  <p>23</p>
                                </span>
                              </span>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            {select.select2 && (
              <div>
                {novelChapters?.length === 0 ? (
                  <NoResult
                    header="No Chapter Found"
                    content="No chapters found for this novel"
                  />
                ) : (
                  <>
                    <div className="book__section__two__catalog">
                      <h1>{`${novelChapters?.length || 0} Chapters`}</h1>
                    </div>
                    {novelChapters.map((chapter) => (
                      <div
                        key={chapter.id}
                        className="book__section__two__catalog__chapter"
                      >
                        <h1>{`Chapter ${parseInt(chapter.chapter)}`}</h1>
                        <h2>{chapter.title}</h2>
                        <p>
                          After recent divorcée Julie has sworn off romance to
                          focus on her marketing career, her friends finally
                          convince her to cautiously dip a toe back in by trying
                          online dating. To Julie's surprise, she seems to hit
                          it off with Blake, a charming architect. But over
                          time, red flags appear revealing that Blake is still
                          secretly obsessed with and harboring hopes to reunite
                          with his ex, Lila.
                        </p>
                        <h4>{`Updated at: ${formatDateFromIso(
                          chapter.updated_at
                        )}`}</h4>
                        <h3>READ</h3>
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}
          </>
        </div>
      </div>
      <Footer />

      <Modal isOpen={isOpen.review} onClose={closeModal}>
        <div className="book__modal__review">
          <h1> Write a review</h1>
          <span>
            <div className="book__modal__review__rating__start">
              <div>
                <h3>Writing quality</h3>
                <span>
                  {Array(Math.round(average))
                    .fill()
                    .map(() => {
                      return <BsStarFill />;
                    })}
                </span>
              </div>
              <div>
                <h3>Writing quality</h3>
                <span>
                  {Array(Math.round(average))
                    .fill()
                    .map(() => {
                      return <BsStarFill />;
                    })}
                </span>
              </div>
              <div>
                <h3>Stability of updates</h3>
                <span>
                  {Array(Math.round(average))
                    .fill()
                    .map(() => {
                      return <BsStarFill />;
                    })}
                </span>
              </div>
              <div>
                <h3>Story Development</h3>
                <span>
                  {Array(Math.round(average))
                    .fill()
                    .map(() => {
                      return <BsStarFill />;
                    })}
                </span>
              </div>
              <div>
                <h3>Character Design</h3>
                <span>
                  {Array(Math.round(average))
                    .fill()
                    .map(() => {
                      return <BsStarFill />;
                    })}
                </span>
              </div>
              <div>
                <h3>World background</h3>
                <span>
                  {Array(Math.round(average))
                    .fill()
                    .map(() => {
                      return <BsStarFill />;
                    })}
                </span>
              </div>
            </div>
            <div className="book__modal__review__rating__end">
              <h3>Total score</h3>
              <h1>0.0</h1>
              <span>
                {Array(Math.round(average))
                  .fill()
                  .map(() => {
                    return <BsStarFill />;
                  })}
              </span>
            </div>
          </span>
          <textarea>
            Type your review here. Your review should be important to the story
            and as detailed as you can. (At least 140 characters)
          </textarea>
          <div className="book__modal__review__cta">
            <span>
              <button>Post</button>
            </span>
          </div>
        </div>
      </Modal>
      <Modal isOpen={isOpen.gift} onClose={closeModal}>
        <div className="book__modal__gift">
          <div className="book__modal__gift__start">
            <div>
              <img alt="item" src={gift1} />
              <span>
                <p>10</p>
                <img src={coin} alt="coin" />
              </span>
            </div>
            <div>
              <img alt="item" src={gift2} />
              <span>
                <p>50</p>
                <img src={coin} alt="coin" />
              </span>
            </div>
            <div>
              <img alt="item" src={gift3} />
              <span>
                <p>100</p>
                <img src={coin} alt="coin" />
              </span>
            </div>
            <div>
              <img alt="item" src={gift4} />
              <span>
                <p>500</p>
                <img src={coin} alt="coin" />
              </span>
            </div>
            <div>
              <img alt="item" src={gift5} />
              <span>
                <p>1000</p>
                <img src={coin} alt="coin" />
              </span>
            </div>
            <div>
              <img alt="item" src={gift6} />
              <span>
                <p>2000</p>
                <img src={coin} alt="coin" />
              </span>
            </div>
            <div>
              <img alt="item" src={gift7} />
              <span>
                <p>5000</p>
                <img src={coin} alt="coin" />
              </span>
            </div>
            <div>
              <img alt="item" src={gift8} />
              <span>
                <p>10000</p>
                <img src={coin} alt="coin" />
              </span>
            </div>
          </div>
          <div className="book__modal__gift__end">
            <button className="book__section__two__ratings__end__bt">
              SEND GIFT
            </button>
            <span>
              <p>Balance: 0 </p>
              <img src={coin} alt="coin" />
            </span>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Book;
