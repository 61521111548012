import Footer from "../../../components/layout/supplementary/footer/Footer";
import Header from "../../../components/layout/supplementary/header/Header";
import "./contact.css";

function Contact() {
  return (
    <>
      <Header />
      <div className="contact">
        <h1>Contact us</h1>
        <p>
          Occaecat minim sunt pariatur sunt aliqua non ut pariatur. Magna
          incididunt tempor incididunt amet fugiat laborum cillum proident
          cillum in qui proident ut. Tempor culpa nulla cupidatat anim officia
          ea.
        </p>
        <h3>Email</h3>
        <p>Info@bonreader.com</p>
        <h3>Phone</h3>
        <p>+2347011122233, +23455566677</p>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
