import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import Login from "./pages/auth/login/Login";
import Signup from "./pages/auth/signup/Signup";
import ScrollToTop from "./utils/ScrollToTop";
import Home from "./pages/main/home/Home";
import Genre from "./pages/main/genre/Genre";
import Ranking from "./pages/main/ranking/Ranking";
import Shelf from "./pages/main/shelf/Shelf";
import Book from "./pages/main/book/Book";
import Account from "./pages/main/account/Account";
import AccountDashboard from "./pages/main/account.dashboard/Account.dashboard";
import AccountStories from "./pages/main/account.stories/Account.stories";
import AccountInbox from "./pages/main/account.inbox/AccountInbox";
import AccountTransactionHistory from "./pages/main/account.transaction.history/AccountTransactionHistory";
import AccountIncome from "./pages/main/acoount.income/Account.income";
import BookPage from "./pages/main/book-page/BookPage";
import PublicRoute from "./route/publicRoute";
import PrivateRoute from "./route/privateRoute";
import Contract from "./pages/main/contract/Contract";
import CreateNovel from "./pages/main/create-novel/CreateNovel";
import Create from "./pages/main/create/Create";
import CreateBook from "./pages/main/create-book/CreateBook";
import ViewNovels from "./pages/main/view-novels/ViewNovels";
import ResetPassword from "./pages/auth/reset-password/ResetPassword";
import ForgetPassword from "./pages/auth/forget-password/ForgetPassword";
import OTP from "./pages/auth/otp/OTP";
import SuccesfulReset from "./pages/auth/successful-reset/SuccessfulReset";
import ToasterContainer from "./components/toast/ToasterContainer";
import EditBook from "./pages/main/edit-book/EditBook";
import BookChapters from "./pages/main/book-chapters/BookChapters";
import { ADMIN_ROLES } from "./config/adminRoles";
import BeAnAuthor from "./pages/supplementary/beAnAuthor/beAnAuthor";
import Terms from "./pages/supplementary/terms/Terms";
import Contact from "./pages/supplementary/contact/Contact";
import Privacy from "./pages/supplementary/privacy/Privacy";
import AccountProfile from "./pages/main/account.profile/Account.profile";
import ScrollToTopIcon from "./components/ui/scroll-to-top-icon/ScrollToTopIcon";
import NotFound from "./pages/supplementary/not-found/notFound";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ToasterContainer />
        <ScrollToTopIcon />
        <ScrollToTop />
        <Routes>
          {/* PUBLIC ROUTES */}
          <Route path="/" element={<PublicRoute />}>
            <Route index element={<Home />} />
            <Route path="signin" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="forget-password" element={<ForgetPassword />} />
            <Route path="successful-reset" element={<SuccesfulReset />} />
            <Route path="otp" element={<OTP />} />
            <Route path="genre" element={<Genre />} />
            <Route path="ranking" element={<Ranking />} />
            <Route path="shelf" element={<Shelf />} />
            <Route path="book/:id" element={<Book />} />
            <Route path="book-page/:id" element={<BookPage />} />
            <Route path="book-chapters/:id" element={<BookChapters />} />
            <Route path="create" element={<Create />} />
            <Route path="create-book" element={<CreateBook />} />
            <Route path="book-chapters" element={<BookChapters />} />
            <Route path="view-novels" element={<ViewNovels />} />
            <Route path="be-an-author" element={<BeAnAuthor />} />
            <Route path="terms-of-use" element={<Terms />} />
            <Route path="contact" element={<Contact />} />
            <Route path="privacy-policy" element={<Privacy />} />

            <Route element={<Account />}>
              <Route path="/account-dashboard" element={<AccountDashboard />} />
              <Route path="/account-stories" element={<AccountStories />} />
              <Route path="/account-profile" element={<AccountProfile />} />
              <Route path="/account-edit-book/:id" element={<EditBook />} />
              <Route path="/account-inbox" element={<AccountInbox />} />
              <Route path="/account-income" element={<AccountIncome />} />
              <Route path="/contract" element={<Contract />} />
              <Route path="/create-novel" element={<CreateNovel />} />
              <Route
                path="/account-transaction-history"
                element={<AccountTransactionHistory />}
              />
            </Route>

            {/* PRIVATE ROUTES */}
            <Route
              element={<PrivateRoute allowedRoles={ADMIN_ROLES} />}
            ></Route>

            {/* CATCH ALL ROUTE */}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
