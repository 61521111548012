import { useState } from "react";
import "./account.profile.css";
import { useAuth } from "../../../redux/actions/authActions";
import { VscAccount } from "react-icons/vsc";
import profileImage from "../../../assets/profile-image.jpg";
import { BiPlus } from "react-icons/bi";

function AccountProfile() {
  const auth = useAuth();
  const [formData, setFormData] = useState({
    firstName: auth.user.first_name,
    lastName: auth.user.last_name,
    penName: auth.user.pen_name,
    gender: auth.user.gender,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    setFormData({
      firstName: auth.user.first_name,
      lastName: auth.user.last_name,
      penName: auth.user.pen_name,
      gender: auth.user.gender,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <div className="ac__profile">
      <h1>Edit Profile</h1>
      <section className="ac__profile__section__one">
        <div className={`ac__profile__section__one__start`}>
          <div className="ac__profile__section__one__avatar">
            <img alt="profile" src={profileImage} />
          </div>
          <h5 className="ac__profile__section__one__header">Update avatar</h5>
          <button>
            <BiPlus /> Upload
          </button>
        </div>

        <form
          onSubmit={handleSubmit}
          className="ac__profile__section__one__end"
        >
          <h6 className="ac__profile__section__one__end__label">First Name</h6>
          <input
            value={formData.firstName}
            type="text"
            name="firstName"
            onChange={handleChange}
            className="ac__profile__section__one__end__input"
          />
          <h6 className="ac__profile__section__one__end__label">Last Name</h6>
          <input
            value={formData.lasttName}
            type="text"
            name="lastName"
            onChange={handleChange}
            className="ac__profile__section__one__end__input"
          />
          <h6 className="ac__profile__section__one__end__label">Pen Name</h6>
          <input
            value={formData.penName}
            type="text"
            name="penName"
            onChange={handleChange}
            className="ac__profile__section__one__end__input"
          />
          <h6 className="ac__profile__section__one__end__label">Gender</h6>
          <select
            value={formData.gender}
            name="gender"
            onChange={handleChange}
            className="ac__profile__section__one__end__input"
          >
            <option value={null}>--</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          <span className="ac__profile__section__one__button__wrap">
            <button
              className="ac__profile__section__one__button--cancel"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button className="ac__profile__section__one__button" type="submit">
              Update
            </button>
          </span>
        </form>
      </section>
    </div>
  );
}

export default AccountProfile;
