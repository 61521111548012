import "./Footer.css";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  return (
    <div className="sup__footer">
      <p onClick={() => navigate("/")}>Home</p>
      <p onClick={() => navigate("/contact")}> Contact us</p>
      <p onClick={() => navigate("/terms-of-use")}> Terms of use</p>
      <p onClick={() => navigate("/privacy-policy")}> Privacy policy</p>
      <p>&copy; Bonreader 2024</p>
    </div>
  );
}

export default Footer;
