import { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import "./Home.css";
import Header from "../../../components/layout/header/Header";
import Footer from "../../../components/layout/footer/Footer";
import ToasterContainer from "../../../components/toast/ToasterContainer";
import Meta from "../../../utils/Meta";

import defaultBookCover from "../../../assets/images/defaultBookCover.png";
import defaultIllustraion from "../../../assets/images/rb_2150696467.png";
import medal1 from "../../../assets/images/medal1.png";
import medal2 from "../../../assets/images/medal2.png";
import medal3 from "../../../assets/images/medal3.png";
import { useFilterNovel } from "../../../redux/actions/bookActions";
import Loading from "../../../components/splash/loading/Loading";
import truncateDescription from "../../../utils/truncate";
import Carousel from "../../../helper/novels/carousel/Carousel";
import "../../../utils/skeleton/home-skeleton.css";

function Home() {
  const filterNovel = useFilterNovel();
  const [rankings, setRankings] = useState([]);
  const [romance, setRomance] = useState([]);
  const [arrivals, setArrivals] = useState([]);
  const [trending, setTrending] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [displayArray, setDisplayArray] = useState([]);
  const [selectedDisplay, setSelectedDisplay] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentDisplayIndex, setCurrentDisplayIndex] = useState("5");
  const [error, setError] = useState(null);

  const getMedalSrc = (i) => {
    switch (i) {
      case 0:
        return medal1;
      case 1:
        return medal2;
      case 2:
        return medal3;
      default:
        return medal1;
    }
  };

  // Toggle the dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    if (completed) {
      setDisplayArray(completed);
      setSelectedDisplay(completed[5]);
    }
  }, [completed]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [
          completedResponse,
          romanceResponse,
          trendingResponse,
          rankingsResponse,
          arrivalsResponse,
        ] = await Promise.all([
          filterNovel({ query: "completed" }),
          filterNovel({ genre: "romance", listing: "popular" }),
          filterNovel({ listing: "trending" }),
          filterNovel({ listing: "popular" }),
          filterNovel({ listing: "new_arrivals" }),
        ]);

        if (Array.isArray(completedResponse.payload?.results)) {
          setCompleted(completedResponse.payload.results);
        } else {
          console.log(completedResponse.message);
        }

        if (Array.isArray(romanceResponse.payload?.results)) {
          setRomance(romanceResponse.payload.results);
        } else {
          console.log(romanceResponse.message);
        }

        if (Array.isArray(trendingResponse.payload?.results)) {
          setTrending(trendingResponse.payload.results);
        } else {
          console.log(trendingResponse.message);
        }

        if (Array.isArray(rankingsResponse.payload?.results)) {
          setRankings(rankingsResponse.payload.results);
        } else {
          console.log(rankingsResponse.message);
        }

        if (Array.isArray(arrivalsResponse.payload?.results)) {
          setArrivals(arrivalsResponse.payload.results);
        } else {
          console.log(arrivalsResponse.message);
        }
      } catch (error) {
        setError(error.response?.message || error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const navigate = useNavigate();
  const [blockNumber, setBlockNumber] = useState(
    window.innerWidth >= 768 ? 8 : 6
  );

  const [isOpen, setIsOpen] = useState(true);
  const toggleModal = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleResize = () => {
      setBlockNumber(window.innerWidth >= 768 ? 8 : 6);
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSelectedItem = (item, i) => {
    setCurrentDisplayIndex(i);
    setSelectedDisplay(item);
    setIsDropdownOpen(false);
  };

  const slides = [1, 2, 3];

  const [currentIndex, setCurrentIndex] = useState(1);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     nextSlide();
  //   }, 3000); // Change slide every 3 seconds

  //   return () => clearInterval(timer); // Clean up the timer
  // }, [currentIndex]);

  return (
    <div className="home">
      <ToasterContainer />
      <Meta title="Home - BONREADER" />
      <div className="home__main">
        <section className="home__section__one">
          <Header />
          <div className="home__section__one__main">
            <div className="home__section__one__main__start">
              {loading && (
                // Render skeleton while loading
                <h1 className="home-skeleton home-skeleton-button"></h1>
              )}

              {!loading && selectedDisplay && (
                <button
                  onClick={toggleDropdown}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 20px",
                    cursor: "pointer",
                    width: "150px",
                  }}
                >
                  <p> {`RANK #${currentDisplayIndex} `}</p>
                  <IoIosArrowDown />
                </button>
              )}

              {/* Dropdown content */}
              {isDropdownOpen && (
                <div
                  className="dropdown-content"
                  style={{
                    position: "absolute",
                    backgroundColor: "white",
                    width: "150px",
                    boxShadow:
                      "0px 8px var(--font-size-primary) 0px rgba(0,0,0,0.2)",
                    zIndex: 10,
                    borderRadius: "var(--border-radius-large)",

                    // padding: "10px 10px",
                    marginTop: "40px",
                  }}
                >
                  {displayArray?.map((item, i) => {
                    return (
                      i < 5 && (
                        <button
                          onClick={() => handleSelectedItem(item, i + 1)}
                          key={i}
                          style={{
                            backgroundColor: "transparent",
                            color: "var(--color-text-secondary)",
                            padding: "20px 10px",
                            border: "none",
                            borderBottom: "1px solid var(--color-border)",
                            cursor: "pointer",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "var(--font-size-secondary)",
                          }}
                        >
                          {`RANK #${i + 1}`}
                        </button>
                      )
                    );
                  })}
                </div>
              )}
              {loading && (
                // Render skeleton while loading
                <h1 className="home-skeleton home-skeleton-title"></h1>
              )}
              <h1>
                {selectedDisplay?.title && selectedDisplay.title.toUpperCase()}
              </h1>
              {loading && (
                // Render skeleton while loading
                <span>
                  <h1 className="home-skeleton home-skeleton-genres"></h1>
                  <h1 className="home-skeleton home-skeleton-genres"></h1>
                </span>
              )}
              <span>
                <h1>
                  {selectedDisplay?.genres &&
                    selectedDisplay.genres[0].toUpperCase()}
                </h1>
                <h1>
                  {selectedDisplay?.author &&
                    selectedDisplay.author.name.toUpperCase()}
                </h1>
              </span>
              {loading && (
                // Render skeleton while loading
                <p className="home-skeleton home-skeleton-description"></p>
              )}
              <p>
                {selectedDisplay?.description &&
                  truncateDescription(selectedDisplay?.description, 300)}
              </p>
              {loading && (
                // Render skeleton while loading
                <p className="home-skeleton home-skeleton-button"></p>
              )}
              {!loading && selectedDisplay && (
                <button onClick={() => navigate(`/book/${selectedDisplay.id}`)}>
                  READ NOW
                </button>
              )}
              {!loading && !selectedDisplay && (
                <div>
                  <img
                    src={defaultIllustraion}
                    style={{
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                </div>
              )}
            </div>
            <div className="home__section__one__main__end">
              {loading && (
                // Render skeleton while loading
                <p className="home-skeleton home-skeleton-image"></p>
              )}
              {!loading && selectedDisplay && (
                <img
                  alt=""
                  src={
                    selectedDisplay?.cover_image
                      ? selectedDisplay.cover_image
                      : defaultBookCover
                  }
                />
              )}
              {!loading && !selectedDisplay && (
                <div>
                  <h5
                    style={{
                      fontWeight: "600",
                      fontSize: "25px",
                      marginBottom: "15px",
                      color: "crimson",
                    }}
                  >
                    OOPS!
                  </h5>
                  <h5
                    style={{
                      fontWeight: "600",
                      fontSize: "16px",
                      lineHeight: "25px",
                      color: "crimson",
                    }}
                  >
                    Could not connect to the server. It may be related to the
                    internet. Check your internet connection and retry.
                  </h5>
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="home__section__two">
          {/* <div className="home__section__two__start">
            <div className="home__section__two__start__block">
              <h1>Original tales</h1>
              <p>Your gateway to infinite stories</p>
            </div>
            <div className="home__section__two__start__block">
              <h1>Become an author</h1>
              <p>Earn as you create stories</p>
            </div>
            <div className="home__section__two__start__block">
              <h1>Author's manual</h1>
              <p>Step by step manual on how you can write top selling novels</p>
            </div>
          </div> */}
          {loading && <Loading />}
          {/* {error && <p>Error: {error}</p>} */}
          {Array.isArray(trending) && trending.length > 0 && (
            <div className="home__section__two__end">
              <h1>Popular Novels</h1>

              <div className="home__section__two__end__section">
                <div className="home__section__two__end__one">
                  <div className="carousel-container">
                    <div className="carousel-wrapper">
                      {romance.map((slide, index) => {
                        const position =
                          index === currentIndex
                            ? "active"
                            : index === currentIndex - 1 ||
                              (currentIndex === 0 &&
                                index === slides.length - 1)
                            ? "prev"
                            : index === currentIndex + 1 ||
                              (currentIndex === slides.length - 1 &&
                                index === 0)
                            ? "next"
                            : "inactive";

                        return (
                          index < 3 && (
                            <div
                              key={index}
                              className={`carousel-slide ${position}`}
                              onClick={() => goToSlide(index)}
                            >
                              <img
                                src={
                                  slide?.cover_image
                                    ? slide.cover_image
                                    : defaultBookCover
                                }
                                alt={slide.alt}
                                className="carousel-image"
                              />
                            </div>
                          )
                        );
                      })}
                    </div>

                    <div className="home__section__two__end__one__carousel__details">
                      <h1>{romance[currentIndex].title}</h1>
                      <span>
                        <h3>{romance[currentIndex].genres[0]}</h3>
                        <h3>{romance[currentIndex].author.name}</h3>
                      </span>
                      <p>
                        {truncateDescription(
                          romance[currentIndex].description,
                          200
                        )}
                      </p>
                    </div>

                    {/* Navigation Arrows */}
                    <button className="carousel-left-arrow" onClick={prevSlide}>
                      &#10094;
                    </button>
                    <button
                      className="carousel-right-arrow"
                      onClick={nextSlide}
                    >
                      &#10095;
                    </button>
                  </div>
                </div>
                <div className="home__section__two__end__two">
                  {trending.map(function (item, i) {
                    // Check if the index is less than six
                    if (i < (window.innerWidth >= 768 ? 10 : 6)) {
                      return (
                        <div onClick={() => navigate(`/book/${item.id}`)}>
                          <img
                            alt=""
                            src={
                              item?.cover_image
                                ? item.cover_image
                                : defaultBookCover
                            }
                          />
                          <h1>{item.title}</h1>
                          <span>
                            <h3>{item.genres[0]}</h3>
                            <h4>{item.read}</h4>
                          </span>
                        </div>
                      );
                    }
                    // If index is greater than or equal to six, return null
                    return null;
                  })}
                </div>
              </div>
            </div>
          )}
        </section>
        {/* <Carousel slides={slides} /> */}
        {Array.isArray(rankings) && rankings.length > 0 && (
          <section className="home__section__three">
            <div className="home__section__three__one">
              <h1>Top Ranked</h1>
              <div className="home__section__three__one__wrap__bg">
                <div className="home__section__three__one__wrap__fg">
                  {rankings.map((item, i) => {
                    if (i < 3) {
                      return (
                        <div
                          key={i}
                          className="home__section__three__one__wrap__fg__img__wrap"
                          onClick={() => navigate(`/book/${item.id}`)}
                        >
                          <div className="home__section__three__one__wrap__fg__img__wrap">
                            <img
                              // alt={`Image of ${item.title}`}
                              src={
                                item?.cover_image
                                  ? item.cover_image
                                  : defaultBookCover
                              }
                            />
                            <img
                              alt={`Medal for ranking ${i}`}
                              src={getMedalSrc(i)}
                            />
                          </div>

                          <h1>{item.title}</h1>
                          <span>
                            <div>
                              <h2>{item.genres[0]}</h2>
                              <h3>{item.author.name}</h3>
                            </div>
                            <h3>{item.read}</h3>
                          </span>
                          {/* <p>
                            {item.description &&
                              truncateDescription(item.description, 100)}
                          </p> */}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
            <div className="home__section__three__two">
              <h1>Rankings</h1>
              <div className="home__section__three__two__trending">
                {rankings.map(function (item, i) {
                  // Check if the index is less than six
                  if (i < 4) {
                    return (
                      <div
                        key={i}
                        className="home__section__three__two__trending__block"
                        onClick={() => navigate(`/book/${item.id}`)}
                      >
                        <h1>{i + 1}</h1>
                        <img
                          alt=""
                          src={
                            item?.cover_image
                              ? item.cover_image
                              : defaultBookCover
                          }
                        />
                        <div>
                          <h1>{item.title}</h1>
                          <span>
                            <h3>{item.genres[0]}</h3>
                            <h4>{item.read}</h4>
                          </span>
                        </div>
                      </div>
                    );
                  }
                  // If index is greater than or equal to six, return null
                  return null;
                })}
              </div>
            </div>
          </section>
        )}
        {Array.isArray(trending) && trending.length > 0 && (
          <section className="home__section__four">
            <span className="home__section__four__heading">
              <h1>Trending</h1>
              {trending.length > 5 && (
                <h2
                  onClick={() =>
                    navigate("/view-novels", {
                      state: { type: "Trending", content: trending },
                    })
                  }
                >
                  See all
                  <BsArrowRight className="home__section__four__heading__icon" />
                </h2>
              )}
            </span>
            <div className="home__section__four__display">
              {trending.map(function (item, i) {
                // Check if the index is less than six
                if (i < blockNumber) {
                  return (
                    <div key={i} onClick={() => navigate(`/book/${item.id}`)}>
                      <img
                        alt=""
                        src={
                          item?.cover_image
                            ? item.cover_image
                            : defaultBookCover
                        }
                      />
                      <h1>{item.title}</h1>
                      <span>
                        <h3>{item.genres[0]}</h3>
                        <h4>{item.read}</h4>
                      </span>
                    </div>
                  );
                }
                // If index is greater than or equal to six, return null
                return null;
              })}
            </div>
          </section>
        )}
        {Array.isArray(romance) && romance.length > 0 && (
          <section className="home__section__five">
            <section className="home__section__four__alt">
              <span className="home__section__four__heading">
                <h1>Top Romance</h1>
                {romance.length > 5 && (
                  <h2
                    onClick={() =>
                      navigate("/view-novels", {
                        state: { type: "Romance", content: romance },
                      })
                    }
                  >
                    See all
                    <BsArrowRight className="home__section__four__heading__icon" />
                  </h2>
                )}
              </span>
              <div className="home__section__four__alt__display">
                {romance.map(function (item, i) {
                  // Check if the index is less than six
                  if (i < blockNumber) {
                    return (
                      <div key={i} onClick={() => navigate(`/book/${item.id}`)}>
                        <img
                          alt=""
                          src={
                            item?.cover_image
                              ? item.cover_image
                              : defaultBookCover
                          }
                        />
                        <div>
                          <h1>{item.title}</h1>
                          <span>
                            <h3>{item.genres[0]}</h3>
                            <h4>{item.read}</h4>
                          </span>
                          <p>
                            {item.description &&
                              truncateDescription(item.description, 60)}
                          </p>
                        </div>
                      </div>
                    );
                  }
                  // If index is greater than or equal to six, return null
                  return null;
                })}
              </div>
            </section>
            {Array.isArray(arrivals) && arrivals.length > 0 && (
              <section className="home__section__four">
                <span className="home__section__four__heading">
                  <h1>New Arrivals</h1>
                  {arrivals?.length > 5 && (
                    <h2
                      onClick={() =>
                        navigate("/view-novels", {
                          state: { type: "New Arrivals", content: arrivals },
                        })
                      }
                    >
                      See all
                      <BsArrowRight className="home__section__four__heading__icon" />
                    </h2>
                  )}
                </span>
                <div className="home__section__four__display">
                  {arrivals.map(function (item, i) {
                    // Check if the index is less than six
                    if (i < blockNumber) {
                      return (
                        <div onClick={() => navigate(`/book/${item.id}`)}>
                          <img
                            alt=""
                            src={
                              item?.cover_image
                                ? item.cover_image
                                : defaultBookCover
                            }
                          />
                          <h1>{item.title}</h1>
                          <span>
                            <h3>{item.genres[0]}</h3>
                            <h4>{item.read}</h4>
                          </span>
                        </div>
                      );
                    }
                    // If index is greater than or equal to six, return null
                    return null;
                  })}
                </div>
              </section>
            )}
          </section>
        )}
        {Array.isArray(completed) && completed.length > 0 && (
          <section className="home__section__four">
            <span className="home__section__four__heading">
              <h1>Completed Novels</h1>
              {completed?.length > 5 && (
                <h2
                  onClick={() =>
                    navigate("/view-novels", {
                      state: { type: "Completed", content: completed },
                    })
                  }
                >
                  See all
                  <BsArrowRight className="home__section__four__heading__icon" />
                </h2>
              )}
            </span>
            <div className="home__section__four__display">
              {completed.map(function (item, i) {
                // Check if the index is less than six
                if (i < blockNumber) {
                  return (
                    <div key={i} onClick={() => navigate(`/book/${item.id}`)}>
                      <img
                        alt=""
                        src={
                          item?.cover_image
                            ? item.cover_image
                            : defaultBookCover
                        }
                      />
                      <h1>{item.title}</h1>
                      <span>
                        <h3>{item.genres[0]}</h3>
                        <h4>{item.read}</h4>
                      </span>
                    </div>
                  );
                }
                // If index is greater than or equal to six, return null
                return null;
              })}
            </div>
          </section>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Home;
