import { useEffect, useState } from "react";
import "./AccountTransactionHistory.css";
import Loading from "../../../components/splash/loading/Loading";
import { useGetUserPayments } from "../../../redux/actions/paymentActions";
import formatDateFromIso from "../../../utils/formatDateFromISo";
import NoResult from "../../../components/splash/no-result/NoResult";

function AccountTransactionHistory() {
  const getUserPayments = useGetUserPayments();
  const [payments, setPayments] = useState([]);
  const [withdrawPayments, setWithdrawPayments] = useState([]);
  const [incomePayments, setIncomePayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [select, setSelect] = useState({
    select1: true,
    select2: false,
  });

  const handleGetUserPayments = async () => {
    try {
      setLoading(true);
      const response = await getUserPayments();
      console.log("barcelona", response);
      if (response.payload && Array.isArray(response.payload.results)) {
        setPayments(response.payload.results);
      } else {
        console.log(response.message || "Failed to fetch payments");
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const separatePayments = () => {
    const withdraw = payments.filter(
      (payment) => payment.payment_type === "withdraw"
    );
    setWithdrawPayments(withdraw);

    const income = payments.filter(
      (payment) => payment.payment_type === "incone"
    );
    setIncomePayments(income);
  };

  useEffect(() => {
    handleGetUserPayments();
  }, []);

  useEffect(() => {
    separatePayments();
  }, [payments]);

  return (
    <div>
      <section className="ac__transaction__section__one">
        <h1>Transaction History</h1>
        <span className="ac__transaction__section__one__nav">
          <h3
            className={
              select.select1
                ? "ac__transaction__section__one__nav__menu--active"
                : "ac__transaction__section__one__nav__menu"
            }
            onClick={() =>
              setSelect((prevState) => ({
                ...prevState,
                select1: true,
                select2: false,
              }))
            }
          >
            Income History
          </h3>
          <h3
            className={
              select.select2
                ? "ac__transaction__section__one__nav__menu--active"
                : "ac__transaction__section__one__nav__menu"
            }
            onClick={() =>
              setSelect((prevState) => ({
                ...prevState,
                select1: false,
                select2: true,
              }))
            }
          >
            Withdrawal History
          </h3>
        </span>
        <hr />
        {select.select1 &&
          (loading ? (
            <Loading />
          ) : incomePayments.length == 0 ? (
            <NoResult
              header={"Income history not found"}
              content={"You do not have any transaction records for income."}
            />
          ) : (
            <div className="ac__transaction__section__two">
              {incomePayments.map((payment) => (
                <>
                  {" "}
                  <div className="ac__stories__section__two__header">
                    <h1 className="ac__stories__section__two__header__stories">
                      S/N
                    </h1>
                    <h1 className="ac__stories__section__two__header__state">
                      Amount
                    </h1>
                    <h1 className="ac__stories__section__two__header__chapter">
                      Period
                    </h1>
                    <h1 className="ac__stories__section__two__header__word">
                      Description
                    </h1>
                    <h1 className="ac__stories__section__two__header__collection">
                      Status
                    </h1>
                    {/* <h1 className="ac__stories__section__two__header__words">
                    Action
                  </h1> */}
                  </div>
                  <div className="ac__stories__section__two__entry">
                    <h1 className="ac__stories__section__two__entry__stories">
                      {payment.id}
                    </h1>
                    <h1 className="ac__stories__section__two__entry__state">
                      {payment.amount}
                    </h1>
                    <h1 className="ac__stories__section__two__entry__chapter">
                      {formatDateFromIso(payment.updated_at)}
                    </h1>
                    <h1 className="ac__stories__section__two__entry__word">
                      {payment.status}
                    </h1>
                    <h1 className="ac__stories__section__two__entry__collection">
                      Approved
                    </h1>
                    {/* <h1 className="ac__stories__section__two__entry__words">
                      ...
                    </h1> */}
                  </div>
                </>
              ))}
            </div>
          ))}
        {select.select2 &&
          (loading ? (
            <Loading />
          ) : withdrawPayments.length == 0 ? (
            <NoResult
              header={"Withdrawal history not found"}
              content={
                "You do not have any transaction records for withdrawals."
              }
            />
          ) : (
            <div className="ac__transaction__section__two">
              {withdrawPayments.map((payment) => (
                <>
                  {" "}
                  <div className="ac__stories__section__two__header">
                    <h1 className="ac__stories__section__two__header__stories">
                      S/N
                    </h1>
                    <h1 className="ac__stories__section__two__header__state">
                      Amount
                    </h1>
                    <h1 className="ac__stories__section__two__header__chapter">
                      Period
                    </h1>
                    <h1 className="ac__stories__section__two__header__word">
                      Description
                    </h1>
                    <h1 className="ac__stories__section__two__header__collection">
                      Status
                    </h1>
                    {/* <h1 className="ac__stories__section__two__header__words">
                    Action
                  </h1> */}
                  </div>
                  <div className="ac__stories__section__two__entry">
                    <h1 className="ac__stories__section__two__entry__stories">
                      {payment.id}
                    </h1>
                    <h1 className="ac__stories__section__two__entry__state">
                      {payment.amount}
                    </h1>
                    <h1 className="ac__stories__section__two__entry__chapter">
                      {formatDateFromIso(payment.updated_at)}
                    </h1>
                    <h1 className="ac__stories__section__two__entry__word">
                      {payment.status}
                    </h1>
                    <h1 className="ac__stories__section__two__entry__collection">
                      Approved
                    </h1>
                    {/* <h1 className="ac__stories__section__two__entry__words">
                      ...
                    </h1> */}
                  </div>
                </>
              ))}
            </div>
          ))}
      </section>
    </div>
  );
}

export default AccountTransactionHistory;
